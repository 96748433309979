/* eslint-disable react/no-unescaped-entities */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';

import { Close } from 'views/icons';

import Input from 'views/components/Input';
import Button from 'views/components/Button';
import useWindowSize from 'hooks/useWindowSize';
import TextButton from 'views/components/TextButton';
import Checkbox from 'views/components/Checkbox';

type FieldProps = {
  name: string;
  label?: string;
  value: string;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  error: boolean;
  errorText?: string;
  disabled?: boolean;
};

interface Props {
  active?: boolean;
  fields: FieldProps[];
  checked?: boolean;
  checkError?: boolean;
  loading?: boolean;

  onCheck?: (checked: boolean) => void;
  onSubmit?: () => void;
  onClose?: () => void;
}

const AMHModal = forwardRef<HTMLDivElement, Props>(
  (
    {
      active,
      checked,
      onClose,
      fields,
      checkError,
      loading,
      onSubmit,
      onCheck,
    },
    ref,
  ) => {
    const [isShowMore, setIsShowMore] = useState<boolean>(false);
    const [customHeight, setCustomHeight] = useState(0);

    const contentRef = useRef<HTMLDivElement>(null);

    const customScrollRef = useRef<HTMLDivElement>(null);

    const { isVerticalScrolled, scrollTop, breakpoint, scrollIsVisible } =
      useWindowSize(contentRef.current, active, isShowMore);

    useEffect(() => {
      if (contentRef && contentRef.current) {
        contentRef.current.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    }, [active]);

    useEffect(() => {
      if (active && contentRef && contentRef.current) {
        setCustomHeight(contentRef.current.scrollHeight);
      }
    }, [active, isShowMore]);

    const onScroll = useCallback(() => {
      if (typeof window !== 'undefined') {
        if (
          contentRef &&
          contentRef.current &&
          customScrollRef &&
          customScrollRef.current
        ) {
          setCustomHeight(contentRef.current.scrollHeight);

          customScrollRef.current.scrollTo({
            top: contentRef.current?.scrollTop,
            behavior: 'smooth',
          });
        }
      }
    }, []);

    useEffect(() => {
      let scrollRefValue: HTMLDivElement | null = null;

      onScroll();

      if (contentRef && contentRef.current) {
        contentRef?.current.addEventListener('scroll', onScroll);

        scrollRefValue = contentRef.current;
      }

      return () => {
        if (scrollRefValue) {
          scrollRefValue.removeEventListener('scroll', onScroll);
        }
      };
    }, [onScroll, isShowMore]);

    const amhModalClassName = cn('amh-modal', {
      'amh-modal_active': active,
    });

    const containerClassName = cn('amh-modal__container', {
      'amh-modal__container_scrolled': isVerticalScrolled,
    });

    const titleClassName = cn('text-semi-bold amh-modal__title', {
      'amh-modal__title_scrolled': scrollTop,
    });

    const renderedDescription = useMemo(() => {
      const text =
        "The AMH test is necessary to provide an accurate and personal prediction. This test measures the level of anti-Müllerian hormone (AMH) in the blood, and it is often used to assess a woman's ability to produce eggs that can be fertilized for pregnancy. It requires only a standard blood sample.";

      if (breakpoint === 'mobile' && !isShowMore) {
        return text.substring(0, 90);
      }

      return text;
    }, [breakpoint, isShowMore]);

    const renderedCustomScrollBar = useMemo(
      () =>
        scrollIsVisible && (
          <div
            className="amh-modal__scrollbar-container"
            ref={customScrollRef}
            style={{ height: contentRef.current?.offsetHeight }}
          >
            <div
              className="amh-modal__scrollbar"
              style={{ height: customHeight }}
            />
          </div>
        ),
      [customHeight, scrollIsVisible],
    );

    const handleCollapseToggle = useCallback(
      () => setIsShowMore((prev) => !prev),
      [],
    );

    const handleClose = useCallback(() => {
      setIsShowMore(false);
      onClose?.();
    }, [onClose]);

    const handleSubmit = useCallback(() => {
      setIsShowMore(false);
      onSubmit?.();
    }, [onSubmit]);

    return (
      <div className={amhModalClassName}>
        <div className="amh-modal__overlay" onClick={handleClose} />
        <div className={containerClassName}>
          <button className="centered amh-modal__close" onClick={handleClose}>
            <Close />
          </button>
          <h3 className={titleClassName}>AMH test</h3>
          {renderedCustomScrollBar}
          <div className="amh-modal__content" ref={contentRef}>
            <div className="amh-modal__header">
              <p className="p2 amh-modal__description">
                {renderedDescription}
                {!isShowMore && (
                  <TextButton
                    className="amh-modal__more"
                    onClick={handleCollapseToggle}
                  >
                    More
                  </TextButton>
                )}
              </p>
              <h2 className="h2 text-medium amh-modal__heading">
                We work with the most advanced clinics
              </h2>
              <h4 className="text-semi-bold amh-modal__subtitle">
                Leave your details below, and we'll refer you to one of these
                clinics to perform the test
              </h4>
            </div>
            <div className="amh-modal__form" ref={ref}>
              {fields.map(
                ({
                  name,
                  value,
                  onChange,
                  onFocus,
                  onBlur,
                  label,
                  error,
                  errorText,
                  disabled,
                }) => (
                  <Input
                    value={value}
                    disabled={disabled}
                    key={name}
                    error={error}
                    label={label}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    required
                    errorText={errorText}
                    wrapClassName="amh-modal__field"
                  />
                ),
              )}
              <Checkbox
                disabled={loading}
                checked={checked}
                error={checkError}
                onChange={onCheck}
                className="amh-modal__checkbox"
                label="By submitting your contact details, you agree that Embryonics may collect, use and disclose
                    your contact details to third parties and approve receiving relevant marketing materials. We
                    do not share any data aside from your name and email address. Good luck on your journey!"
              />
              <div className="amh-modal__footer">
                <Button
                  className="amh-modal__btn"
                  onClick={handleSubmit}
                  disabled={loading}
                  loading={loading}
                >
                  Get in touch
                </Button>
                <img
                  src="./img/amh-img.svg"
                  alt="amh-img"
                  className="amh-modal__img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

export default AMHModal;
