import React, { FC } from 'react';

export const Lamp: FC = () => (
  <svg viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.3303 12.5825c1.9998-5.99998-3.50022-8.99998-9.00022-6.99998l3.00024-4 3-.5 3.99998 1.5 2.5 4-1 3.99998-2.5 2ZM8.33008 19.0825c-.5-.5-.5 0-2.5-1.5l-.5-3 3 .5h1.5l.50002 3h-.50002l-1.5 1Z"
      fill="#fff"
    />
    <path
      clipRule="evenodd"
      d="M11.5301 13.2199c-.7024.4288-1.2386 1.1388-1.2386 1.9617v1.7031c0 1.2143-.98355 2.1978-2.19788 2.1978-1.21432 0-2.19787-.9835-2.19787-2.1978v-1.7031c0-.8229-.53616-1.5329-1.23858-1.9617C2.76382 12.0642 1.5 9.97837 1.5 7.59362 1.5 3.95065 4.45065 1 8.09362 1c3.64298 0 6.59358 2.95065 6.59358 6.59362 0 2.38475-1.2638 4.47058-3.1571 5.62628Z"
      stroke="#EA2A5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.09375 15.0825v-4.1921"
      stroke="#EA2A5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M5.89453 15.0825h3.84628-3.84628ZM6.44434 9.24207l1.6484 1.64843-1.6484-1.64843ZM9.74216 9.24207 8.09375 10.8905l1.64841-1.64843Z"
      stroke="#EA2A5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
