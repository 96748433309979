import React, { FC, useCallback } from 'react';
import cn from 'classnames';

import RangeSlider from '../RangeSlider';
import InputWithSwitch from '../InputWithSwitch/InputWithSwitch';

interface Props {
  className?: string;
  label?: string;
  required?: boolean;
  value?: string;
  unit?: string;
  units: { label: string; value: string }[];
  htmlFor?: string;
  step?: number;
  min?: number;
  max?: number;
  maxValue?: number;
  minValue?: number;
  error?: boolean;
  maxChars?: number;
  invalidChars?: string[];
  size?: 'md' | 'lg';

  onChange?: (value: string, unit: string) => void;
}

const InputWithSwitchAndSlider: FC<Props> = ({
  className,
  label = 'Weight',
  required,
  value,
  unit,
  units,
  size,
  htmlFor,
  step = 1,
  maxValue = 10,
  minValue = 0,
  min = 0,
  max = 100,
  error,
  maxChars,
  invalidChars,
  onChange,
}) => {
  const inputWithSliderClassName = cn('input-slider', className);

  const handleInputChange = useCallback(
    (inputValue: string, switchUnit: string) => {
      if (+inputValue > max) {
        onChange?.(max.toString(), switchUnit);
      } else if (+inputValue < min) {
        onChange?.(min.toString(), switchUnit);
      } else {
        onChange?.(inputValue, switchUnit);
      }
    },
    [max, min, onChange],
  );

  const handleSliderChange = useCallback(
    (sliderValue: number) => {
      onChange?.(
        sliderValue > 0 ? sliderValue.toString().replace(',', '.') : '',
        unit || '',
      );
    },
    [onChange, unit],
  );

  return (
    <div className={inputWithSliderClassName}>
      <div className="aife jcsb input-slider__inner">
        <InputWithSwitch
          label={label}
          value={value && maxValue && +value > maxValue ? `${maxValue}` : value}
          onChange={handleInputChange}
          className="input-slider__input"
          prefix={value && maxValue && +value > maxValue ? '>' : ''}
          units={units}
          unit={unit}
          htmlFor={htmlFor}
          error={error}
          maxChars={value && maxValue && +value > maxValue ? 1 : maxChars}
          invalidChars={invalidChars}
          size={size}
          required={required}
        />
        <RangeSlider
          step={step}
          value={value ? +value : undefined}
          minValue={minValue}
          min={min}
          max={max}
          onChange={handleSliderChange}
          className="input-slider__slider input-slider__slider_mb"
        />
      </div>
    </div>
  );
};

export default InputWithSwitchAndSlider;
