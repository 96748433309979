import React, { FC } from 'react';

export const ChevronDown: FC = () => (
  <svg viewBox="0 0 24 24" stroke="#4D565C" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m4.343 9.5 7.57 7.57 7.57-7.57"
      fill="none"
      strokeWidth="1.9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
