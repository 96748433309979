import React, { FC } from 'react';

export const Implanted: FC = () => (
  <svg viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m7.01 10.69-1.639 4.372c1.64 2.733 5.496 3.69 10.384 2.187l-.546 1.64-2.733 2.185H8.65l-4.372-1.093-2.186-2.733-.547-3.825L2.638 9.05l2.733-1.093 1.093.546.547 2.186ZM14.702 7.405l2.99-2.387 2.812 2.65c-2.986-1.113-5.533 1.477-5.977 4.773-.444 3.295-1.084-2.05-1.084-2.05l1.26-2.986Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.453 8.73c-1.268.516-2.578 2.925-2.431 5.555.091 1.634.858 4.549 4.967 5.707 5.849 1.65 8.205-2.112 8.946-3.806a4.618 4.618 0 0 1 1.123-1.58l.26-.236c.374-.34.822-.624 1.368-.868 1.274-.567 2.057-1.642 2.15-2.951.09-1.257-.481-2.43-1.489-3.064-1.04-.653-2.094-.88-3.056-.654-.908.214-1.707.824-2.252 1.717-.48.788-.386 2.003-.111 2.493.256.456.248.997-.023 1.447-.27.45-.744.71-1.266.699a11.53 11.53 0 0 1-2.388-.327c-.873-.213-1.56-.826-1.984-1.773a.515.515 0 0 0-.49-.31l-.599.023a.811.811 0 0 1-.599-.232.814.814 0 0 1-.24-.591c.01-.484-.098-1.134-.44-1.369-.354-.243-.982-.069-1.446.12Zm2.171 12.556C3.036 20.274.87 17.749.68 14.36c-.167-2.99 1.298-6.073 3.266-6.874 1.121-.457 2.034-.451 2.714.017.701.482.93 1.315.998 1.936l.068-.002c.76-.03 1.454.404 1.767 1.103.253.562.614.904 1.076 1.016.693.169 1.459.275 2.1.29.013 0 .055 0 .085-.049a.09.09 0 0 0 .002-.096c-.51-.908-.603-2.638.135-3.85.734-1.203 1.831-2.029 3.092-2.326 1.315-.308 2.727-.025 4.08.824 1.428.898 2.238 2.545 2.114 4.297-.128 1.793-1.228 3.32-2.944 4.083a3.766 3.766 0 0 0-1.012.635l-.259.236a3.285 3.285 0 0 0-.796 1.124c-.546 1.249-2.419 4.64-6.694 4.994-1.113.093-2.389-.021-3.848-.432Z"
      fill="#EA2A5C"
      stroke="#EA2A5C"
      strokeWidth=".2"
    />
    <path
      d="M6.5 1.5c.974.681 4.709 1.87 3.137 4.89C7.5 10.5 9.5 12.5 12.5 12.5"
      stroke="#EA2A5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.193 15.566a.671.671 0 0 1-.618-1.036c1.194-1.835 1.772-4.64 1.778-4.67a.672.672 0 1 1 1.317.267c-.026.125-.633 3.082-1.97 5.135a.67.67 0 0 1-.507.303ZM14.302 13.63a.671.671 0 0 1-.595-.268 8.703 8.703 0 0 1-.988-1.737.671.671 0 0 1 1.245-.506c.003.008.314.762.82 1.438a.672.672 0 0 1-.482 1.072Z"
      fill="#EA2A5C"
      stroke="#EA2A5C"
      strokeWidth=".2"
    />
  </svg>
);
