import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';

interface Props {
  text?: string;
  size?: 'lg' | 'auto';
  position?:
    | 'left'
    | 'right'
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right';
  className?: string;
  wrapClassName?: string;
  children?: ReactNode;

  getVisible?: (visible: boolean) => void;
}

const Tooltip: FC<Props> = ({
  children,
  text,
  size,
  position = 'bottom-left',
  className,
  getVisible,
  wrapClassName,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isVisible, setVisibility] = useState(false);

  const wrapTooltipClassName = cn('tooltip__container', wrapClassName);

  const tooltipClassName = cn(
    `tooltip tooltip_${position} tooltip_${size}`,
    {
      tooltip_active: isVisible,
    },
    className,
  );

  const renderedTooltip = isVisible && text && (
    <div className={tooltipClassName}>
      <div className="tooltip__triangle" />
      <p className="p3 tooltip__text">{text}</p>
    </div>
  );

  const handleMouseOver = useCallback(() => {
    getVisible?.(true);

    setVisibility(true);
  }, [getVisible]);
  const handleMouseOut = useCallback(() => {
    getVisible?.(false);

    setVisibility(false);
  }, [getVisible]);

  useEffect(() => {
    const node = ref.current;
    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);
      node.addEventListener('click', handleMouseOver);

      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
        node.removeEventListener('click', handleMouseOver);
      };
    }
  }, [handleMouseOver, handleMouseOut]);

  return (
    <div ref={ref} className={wrapTooltipClassName}>
      {children}
      {renderedTooltip}
    </div>
  );
};

export default Tooltip;
