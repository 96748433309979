import React, { FC } from 'react';

export const Fertilized: FC = () => (
  <svg viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.64 13.694c7.65 3.825 12.567-3.278 8.76-8.044 4.262.429 8.908 2.21 9.052 6.86.042 1.375-.434 4.486-.434 4.486l-2.113 3.522-4.93 2.113H8.453l-.868-1.139-3.76-.974-2.185-6.824Z"
      fill="#fff"
    />
    <circle
      r="1.77"
      transform="matrix(-1 0 0 1 12.956 15.55)"
      stroke="#EA2A5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.45 13.779a8.851 8.851 0 1 0 8.85-8.851M4.093 9.398c-.982-.333-2.945-1.328-3.04-2.737-.117-1.762.841-2.746 1.77-3.12.93-.374 2.738-.582 3.543-2.04"
      stroke="#EA2A5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.128 11.308c.93.594 1.96.645 2.3.113.34-.531-.14-1.444-1.07-2.039-.93-.594-1.922-.522-2.265.015-.344.538.105 1.316 1.035 1.91Z"
      stroke="#EA2A5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      r=".492"
      transform="matrix(-1 0 0 1 14.726 10.337)"
      stroke="#EA2A5C"
      strokeWidth=".983"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
