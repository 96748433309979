/* eslint-disable react/no-unescaped-entities */
import React, { FC } from 'react';
import cn from 'classnames';

import { LoadingIcon } from 'views/icons';

import Button from 'views/components/Button';

interface Props {
  active?: boolean;

  onClose?: () => void;
}

const LoadingModal: FC<Props> = ({ active, onClose }) => {
  const loadingModalClassName = cn('loading-modal', {
    'loading-modal_active': active,
  });

  return (
    <div className={loadingModalClassName}>
      <div className="amh-modal__overlay" />
      <div className="centered loading-modal__container">
        <div className="aic fdc loading-modal__content">
          <span className="centered loading-modal__img">
            <LoadingIcon />
          </span>
          <h3 className="h2 loading-modal__heading">Please wait</h3>
          <p className="p2 loading-modal__text">
            We are processing your information...
          </p>
          <Button className="loading-modal__btn" onClick={onClose} white>
            Back to form
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LoadingModal;
