/* eslint-disable react/no-unescaped-entities */
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';

import { ArrowRight, Lamp } from 'views/icons';

import useWindowSize from 'hooks/useWindowSize';

import Input from 'views/components/Input';
import Multiselect from 'views/components/Multiselect';
import Checkbox from 'views/components/Checkbox';
import Button from 'views/components/Button';
import TextButton from 'views/components/TextButton';
import Switch from 'views/components/Switch';
import InputWithSwitchAndSlider from 'views/components/InputWithSwitchAndSlider';
import Tabs from 'views/components/Tabs';
import useCalculatorFormContainer from './hooks/useCalculatorFormContainer';

interface Props {
  controller?: any;
  amhModalIsOpen?: boolean;

  openAmhModal: () => void;
  setLoading?: (loading: boolean) => void;
  openResultModal?: (type: 'error' | 'success') => void;
}

const CalculatorForm: FC<Props> = ({
  controller,
  amhModalIsOpen,
  openAmhModal,
  setLoading,
  openResultModal,
}) => {
  const [isShowMore, setIsShowMore] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);

  const { breakpoint } = useWindowSize();

  const {
    age,
    height,
    actualHeight,
    inchesHeight,
    footsHeight,
    weight,
    amh,
    diagnosesCheckboxesData,
    diagnoses,
    origins,
    originsData,
    errorText,
    schema,
    tabsProps,
    focusedId,
    handleSchemaChange,
    handleAgeChange,
    handleAgeFocus,
    handleAmhChange,
    handleDiagnosesChange,
    handleHeightChange,
    handleHeightFocus,
    handleFootsHeightChange,
    handleInchesHeightChange,
    handleOriginsChange,
    handleSubmit,
    handleWeightChange,
    handleWightFocus,
    checkFieldValidation,
    handleOriginsFocus,
    validFieldErrors,
  } = useCalculatorFormContainer(
    ref.current,
    setLoading,
    openResultModal,
    controller,
    amhModalIsOpen,
  );

  const renderedBodySection =
    schema === 'standard' ? (
      <div className="fww calculator-form__body calculator-form__body_reverted">
        <div className="df calculator-form__height">
          <Input
            label="Height"
            required
            wrapClassName="calculator-form__field calculator-form__field_small"
            value={footsHeight}
            onChange={handleFootsHeightChange}
            error={validFieldErrors.includes('height')}
            maxChars={3}
            invalidChars={['+', '-', ',', 'e', 'E']}
            suffix="ft"
            type="number"
            errorText="Please enter your height"
            onFocus={handleHeightFocus('foot-height')}
            onBlur={checkFieldValidation(actualHeight, 'height')}
            focusedId={focusedId}
            id="foot-height"
          />
          <Input
            wrapClassName="calculator-form__field calculator-form__field_small"
            value={inchesHeight}
            onChange={handleInchesHeightChange}
            error={validFieldErrors.includes('height')}
            maxChars={4}
            invalidChars={['+', '-', ',', 'e', 'E']}
            suffix="in"
            errorText=""
            type="number"
            onFocus={handleHeightFocus('inch-height')}
            onBlur={checkFieldValidation(actualHeight, 'height')}
            id="inch-height"
            focusedId={focusedId}
          />
        </div>
        <Input
          label="Weight"
          required
          wrapClassName="calculator-form__field calculator-form__field_small"
          value={weight}
          suffix="lb"
          onChange={handleWeightChange}
          error={validFieldErrors.includes('weight')}
          maxChars={6}
          invalidChars={['+', '-', ',', 'e', 'E']}
          type="number"
          errorText="Please enter your weight"
          onFocus={handleWightFocus('weight')}
          onBlur={checkFieldValidation(weight, 'weight')}
          focusedId={focusedId}
          id="weight"
        />
      </div>
    ) : (
      <div className="fww calculator-form__body">
        <Input
          label="Height"
          required
          wrapClassName="calculator-form__field calculator-form__field_small"
          value={height}
          onChange={handleHeightChange}
          error={validFieldErrors.includes('height')}
          maxChars={5}
          invalidChars={['+', '-', ',', 'e', 'E']}
          suffix="cm"
          type="number"
          errorText="Please enter your height"
          onFocus={handleHeightFocus('height')}
          onBlur={checkFieldValidation(height, 'height')}
          id="height"
          focusedId={focusedId}
        />
        <Input
          label="Weight"
          required
          wrapClassName="calculator-form__field calculator-form__field_small"
          value={weight}
          onChange={handleWeightChange}
          error={validFieldErrors.includes('weight')}
          maxChars={5}
          invalidChars={['+', '-', ',', 'e', 'E']}
          suffix="kg"
          type="number"
          errorText="Please enter your weight"
          onFocus={handleWightFocus('weight')}
          onBlur={checkFieldValidation(weight, 'weight')}
          id="weight"
          focusedId={focusedId}
        />
      </div>
    );

  const renderedAmhSection = useMemo(() => {
    if (tabsProps.activeTab) {
      if (tabsProps.activeTab === 'yes') {
        return (
          <InputWithSwitchAndSlider
            className="calculator-form__ahm"
            label="Please enter your AMH level"
            step={amh.unit && amh.unit === 'ng/mL' ? 0.1 : 0.5}
            min={0}
            max={amh.unit && amh.unit === 'ng/mL' ? 5.1 : 40.1}
            value={amh.value}
            units={[
              { label: 'ng/mL', value: 'ng/mL' },
              { label: 'pmol/L', value: 'pmol/L' },
            ]}
            unit={amh.unit}
            onChange={handleAmhChange}
            maxValue={amh.unit && amh.unit === 'ng/mL' ? 5 : 40}
            htmlFor="amh"
            maxChars={5}
            invalidChars={['+', '-', 'e', 'E', ',']}
            size="md"
            required={false}
          />
        );
      }
      return (
        <>
          <p className="p3 calculator-form__text calculator-form__text_accent">
            That's OK, you can proceed without it
          </p>
          <p className="p3 calculator-form__text">
            But we highly recommend that you perform this blood test and come
            back again to see more accurate results.
          </p>
          <TextButton
            onClick={openAmhModal}
            className="aic p3 text-semi-bold calculator-form__link"
          >
            How to get your AMH level checked
            <span className="centered calculator-form__arrow">
              <ArrowRight />
            </span>
          </TextButton>
        </>
      );
    }
    return null;
  }, [amh.unit, amh.value, handleAmhChange, openAmhModal, tabsProps.activeTab]);

  const renderedDisclaimer = useMemo(() => {
    const text =
      'Disclaimer: Rhea’ Egg Freezing Calculator is not intended to provide medical advice, diagnoses or treatment options and is intended to be used for informational purposes only. Please consult a fertility specialist for any diagnostic or treatment queries. All results are estimates based on the information provided and may not reflect actual results. Various factors including, but not limited to, certain values, medical conditions, and/or lifestyle choices may decrease the accuracy of the results. This egg freezing calculator will be under continued review. Future versions of the calculator’s prediction model may produce different results as training on new data is performed. Links provided are available to the general public and are not produced by Embryonics. Embryonics makes no guarantee that the information they contain is accurate or up to date, nor does it recommend any specific treatment options contained within these resources. Please contact a health professional for any additional advice.';

    if (!isShowMore) {
      if (breakpoint === 'mobile') {
        return text.substring(0, 260);
      }

      return text.substring(0, 356);
    }

    return text;
  }, [breakpoint, isShowMore]);

  const handleCollapseToggle = useCallback(
    () => setIsShowMore((prev) => !prev),
    [],
  );

  return (
    <section className="calculator-form">
      <div className="calculator-form__info">
        <h2 className="h2 text-medium calculator-form__heading">
          Considering <strong className="calculator-form__marker">going</strong>{' '}
          through fertility preservation?
        </h2>
        <p className="p1 calculator-form__description">
          This calculator predicts the number of eggs that will be retrieved and
          the likelihood of achieving a live birth, so you get a sense of how
          many cycles you will need to do according to your goals.
        </p>
      </div>
      <div className="calculator-form__header" ref={ref}>
        <h3 className="h3 text-semi-bold calculator-form__title">
          Your
          <strong className="calculator-form__marker calculator-form__marker_tablet">
            details
          </strong>
        </h3>
        <p className="p3 calculator-form__text">
          The calculator is completely anonymous and the details are not stored
          anywhere
        </p>
      </div>
      <div className="calculator-form__form">
        <Input
          label="Age"
          required
          wrapClassName="calculator-form__field calculator-form__field_small calculator-form__field_mb"
          type="number"
          value={age}
          onChange={handleAgeChange}
          error={validFieldErrors.includes('age')}
          maxChars={3}
          invalidChars={['+', '-', 'e', 'E', '.', ',']}
          errorText={errorText || 'Please enter your age'}
          onFocus={handleAgeFocus('age')}
          onBlur={checkFieldValidation(age, 'age')}
          focusedId={focusedId}
          id="age"
        />
        <div className="calculator-form__divider" />
        <Switch
          className="calculator-form__switch"
          units={[
            { label: 'Standard', value: 'standard' },
            { label: 'Metric', value: 'metric' },
          ]}
          size="lg"
          unit={schema}
          onChange={handleSchemaChange}
        />
        {renderedBodySection}
        <div className="calculator-form__divider" />
        <Multiselect
          label="Ethnicity"
          description="You can select up to 4 options"
          selectedValues={origins}
          onChange={handleOriginsChange}
          className="calculator-form__field calculator-form__field_mb"
          htmlFor="ethnicity"
          focusedId={focusedId}
          options={originsData}
          max={4}
          placeholder="Select Ethnicity"
          onFocus={handleOriginsFocus}
        />
        <div className="calculator-form__divider" />
        <h3 className="p2 text-semi-bold calculator-form__subtitle text-semi-bold calculator-form__subtitle_margin_custom">
          AMH level
        </h3>
        <p className="p3 calculator-form__text calculator-form__text_mb calculator-form__text_amh">
          AMH test measures the level of anti-Müllerian hormone (AMH) in the
          blood. {breakpoint === 'desk' && <br />} It requires only a standard
          blood sample, and necessary to provide an accurate and personal
          prediction.
        </p>
        <h3 className="p2 text-semi-bold calculator-form__subtitle">
          Do you know your AMH level?
        </h3>
        <Tabs
          {...tabsProps}
          withCancel={false}
          size="sm"
          className="calculator-form__tabs"
        />
        {renderedAmhSection}
        <div className="calculator-form__divider" />
        <h3 className="p2 text-semi-bold calculator-form__subtitle">
          Have you ever been diagnosed with any of the following?
        </h3>
        {diagnosesCheckboxesData.map(({ label, tooltipText }) => (
          <Checkbox
            label={label}
            onChange={() => handleDiagnosesChange(label)}
            checked={diagnoses.includes(label)}
            className="calculator-form__checkbox"
            key={label}
            filled
            tooltipText={tooltipText}
          />
        ))}
        <div className="aifs calculator-form__disclaimer">
          <span className="centered calculator-form__lamp">
            <Lamp />
          </span>
          <p className="p4 calculator-form__text">
            {renderedDisclaimer}
            {!isShowMore && (
              <TextButton
                className="calculator-form__more"
                onClick={handleCollapseToggle}
              >
                More
              </TextButton>
            )}
          </p>
        </div>
        <Button className="calculator-form__btn" onClick={handleSubmit}>
          Show results
        </Button>
      </div>
    </section>
  );
};

export default CalculatorForm;
