import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES, SOCIAL_ROUTES } from 'utils/constants/routes';

import { Logo } from 'views/icons';

const Footer: FC = () => (
  <footer className="df footer">
    <div className="df aic footer__links">
      <Link to={ROUTES.home} className="footer__logo" reloadDocument>
        <Logo />
      </Link>
      <div className="aic footer__socials">
        {SOCIAL_ROUTES.map(({ id, route, icon }) => (
          <a
            href={route}
            className="centered footer__link"
            target="_blank"
            rel="noopener noreferrer"
            key={id}
          >
            <span className="centered footer__icon">{icon}</span>
          </a>
        ))}
      </div>
    </div>
    <div className="footer__about">
      <h3 className="p3  text-semi-bold footer__title">
        Let us introduce ourselves
      </h3>
      <p className="p4 footer__text">
        We at Rhea produce a range of AI-based tools that take fertility
        treatments one step further. We work with clinics and doctors around the
        world.
      </p>
    </div>
    <div className="footer__contacts">
      <h3 className="p3  text-semi-bold footer__title">Contact</h3>
      <p className="p4 footer__text">Rhea Labs</p>
      <a href="mailto:info@embryonics.me" className="p4 footer__email">
        info@embryonics.me
      </a>
    </div>
  </footer>
);

export default Footer;
