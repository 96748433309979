import React, { FC } from 'react';

export const ArrowRight: FC = () => (
  <svg viewBox="0 0 16 17" stroke="#EA2A5C" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m11 5.5 4 4m0 0-4 4m4-4H1"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
