import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import cn from 'classnames';
import { nanoid } from 'nanoid';

import { Close, Info } from 'views/icons';

import useWindowSize from 'hooks/useWindowSize';

import ProcessCard from 'views/components/ProcessCard';
import TextButton from 'views/components/TextButton';

interface Props {
  active?: boolean;
  cards?: ProcessCardProps[];

  onClose?: () => void;
}

const ProcessModal: FC<Props> = ({ active = true, cards, onClose }) => {
  const [scrollLeft, setScrollLeft] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isShowMore, setIsShowMore] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);
  const container = useRef<HTMLDivElement>(null);

  const { isVerticalScrolled, scrollTop, breakpoint } = useWindowSize(
    ref.current,
    active,
    isShowMore,
  );

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [active]);

  const processModalClassName = cn('process-modal', {
    'process-modal_active': active,
  });

  const containerClassName = cn('process-modal__container', {
    'process-modal__container_scrolled': isVerticalScrolled,
  });

  const headerClassName = cn('process-modal__header', {
    'process-modal__header_scrolled': scrollTop,
  });

  const cardsClassName = cn(
    'aic process-modal__cards process-modal__cards_drag',
    {
      'process-modal__cards_scrolled': scrollLeft > 0 && scrollLeft !== 100,
      'process-modal__cards_scrolled-end': scrollLeft === 100,
    },
  );

  const renderedDescription = useMemo(() => {
    const text =
      'In each step of the IVF process, the number of viable candidates for live birth slowly decreases (also known as “attrition rate”). This may seem concerning, but there is no need to worry because it is completely normal and happens in natural conception too. Below is a step-by-step example of an IVF cycle attrition rate from egg retrieval to live birth.';

    if (breakpoint === 'mobile' && !isShowMore) {
      return text.substring(0, 86);
    }

    return text;
  }, [breakpoint, isShowMore]);

  const handleClose = useCallback(() => {
    setIsShowMore(false);
    onClose?.();
  }, [onClose]);

  const renderedCards = useMemo(
    () =>
      cards?.map((card, index) => (
        <ProcessCard
          title={card.title}
          value={card.value}
          subtitle={card.subtitle}
          icon={card.icon}
          nextValue={cards[index + 1] ? cards[index + 1].value : 0}
          prevValue={cards[index - 1] ? cards[index - 1].value : undefined}
          text={card.text}
          className="process-modal__card"
          key={nanoid(5)}
          custom={card.custom}
        />
      )),
    [cards],
  );

  const onHorizontalScrollChange = useCallback(
    (element: HTMLDivElement | null) => {
      if (element) {
        if (element.scrollLeft > 0 && scrollLeft === 0) {
          setScrollLeft(1);
        } else if (
          element.scrollWidth - element.scrollLeft - element.offsetWidth <
          6
        ) {
          setScrollLeft(100);
        } else if (element.scrollLeft === 0) {
          setScrollLeft(0);
        } else {
          setScrollLeft(1);
        }
      }
    },
    [scrollLeft],
  );

  const onWheelScroll = useCallback(
    (e: WheelEvent, element: HTMLDivElement | null) => {
      if (element) {
        element.scrollTo({
          left: element.scrollLeft + e.deltaY * 100,
          behavior: 'smooth',
        });
      }
    },
    [],
  );

  useEffect(() => {
    let containerRefValue: HTMLDivElement | null = null;

    if (
      container &&
      container.current &&
      (breakpoint === 'laptop' || breakpoint === 'desk')
    ) {
      container.current.addEventListener('wheel', (e) =>
        onWheelScroll(e, container.current),
      );

      containerRefValue = container.current;
    }

    return () => {
      if (containerRefValue) {
        containerRefValue.removeEventListener('wheel', (e) =>
          onWheelScroll(e, containerRefValue),
        );
      }
    };
  }, [breakpoint, onWheelScroll]);

  const handleCollapseToggle = useCallback(
    () => setIsShowMore((prev) => !prev),
    [],
  );

  const renderedCardsSection = useMemo(
    () =>
      breakpoint === 'desk' ? (
        <div className={cardsClassName}>
          {' '}
          <ScrollContainer
            innerRef={container}
            onScroll={() => onHorizontalScrollChange(container.current)}
            horizontal
            className="aic process-modal__track"
            hideScrollbars={false}
          >
            {renderedCards}
          </ScrollContainer>
        </div>
      ) : (
        <div className="aic process-modal__track">{renderedCards}</div>
      ),
    [breakpoint, cardsClassName, onHorizontalScrollChange, renderedCards],
  );

  return (
    <div className={processModalClassName}>
      <div className="process-modal__overlay" onClick={handleClose} />
      <div className={containerClassName}>
        <button className="centered process-modal__close" onClick={handleClose}>
          <Close />
        </button>
        <div className={headerClassName}>
          <h3 className="h2 text-medium process-modal__title">
            Why it takes so many eggs to achieve one live birth
          </h3>
        </div>
        <div className="process-modal__content" ref={ref}>
          <p className="p2 process-modal__description">
            {renderedDescription}
            {!isShowMore && (
              <TextButton
                className="amh-modal__more"
                onClick={handleCollapseToggle}
              >
                More
              </TextButton>
            )}
          </p>
          <h4 className="text-semi-bold process-modal__subtitle">
            The average decrease in the number of eggs in each step of the
            process
          </h4>
          <span className="aic p2 process-modal__info">
            <span className="centered process-modal__tooltip">
              <Info />
            </span>
            For women under 35
          </span>
          {renderedCardsSection}
        </div>
      </div>
    </div>
  );
};

export default ProcessModal;
