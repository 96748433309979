import React, { FC } from 'react';

export const Question: FC = () => (
  <svg
    viewBox="0 0 18 18"
    fill="#4D565C"
    stroke="#4D565C"
    fillOpacity="0"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 17c4.4183 0 8-3.5817 8-8 0-4.41828-3.5817-8-8-8-4.41828 0-8 3.58172-8 8 0 4.4183 3.58172 8 8 8Z"
      fill="#ACB8C0"
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity=".99"
      d="M9.14668 13.2c.38501 0 .69669-.3137.69669-.7 0-.3864-.31168-.7-.69669-.7-.385 0-.69668.3136-.69668.7 0 .3863.31168.7.69668.7Z"
      strokeWidth=".1"
      fillOpacity="1"
    />
    <path
      opacity=".99"
      d="M9.11937 10.1818v-.1433c0-.25193.20967-.56495.62902-.93901.26011-.23203.50201-.48901.55991-.56026.2374-.29201.3793-.66157.3793-1.06351 0-.94493-.78397-1.71095-1.75103-1.71095-.96706 0-1.75102.76602-1.75102 1.71095"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fillOpacity="0"
    />
  </svg>
);
