import React, { FC, useCallback, useRef } from 'react';

import Layout from 'views/hoc/Layout';

import NextSteps from 'views/features/NextSteps';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'utils/constants/routes';
import usePopupsContainer from 'hooks/usePopupsContainer';
import ResultModal from 'views/features/modals/ResultModal';

const NextStepsPage: FC = () => {
  const formRef = useRef<HTMLDivElement>(null);

  const { amhModalProps, resultModalProps, subscriptionFormProps } =
    usePopupsContainer();

  const {
    state: { userData, results },
  } = useLocation() as {
    state: { userData: UserData; results: RoundResult[] };
  };

  const navigate = useNavigate();

  const handleBack = useCallback(() => {
    if (userData && results) {
      navigate(ROUTES.results, { state: { userData, results } });
    } else {
      navigate(ROUTES.home);
    }
  }, [navigate, results, userData]);

  return (
    <Layout>
      <NextSteps
        {...amhModalProps}
        ref={formRef}
        subscriptionFormProps={subscriptionFormProps}
        handleBack={handleBack}
      />
      <ResultModal {...resultModalProps} />
    </Layout>
  );
};

export default NextStepsPage;
