import React, { FC, useCallback } from 'react';
import cn from 'classnames';

import TextButton from '../TextButton';

interface Props {
  className?: string;
  activeTab: string;
  withCancel?: boolean;
  size?: 'sm';
  tabs: {
    value: string;
    label: string;
  }[];

  onChange?: (value: string) => void;
  onClear?: () => void;
}

const Tabs: FC<Props> = ({
  activeTab,
  className,
  tabs,
  withCancel = true,
  size,
  onChange,
  onClear,
}) => {
  const tabsClassName = cn('df tabs', className);

  const handleChange = useCallback(
    (value: string) => () => onChange?.(value),
    [onChange],
  );

  const renderedCancelBtn = activeTab && withCancel && (
    <TextButton onClick={onClear} className="tabs__cancel">
      Cancel
    </TextButton>
  );

  return (
    <div className={tabsClassName}>
      <div className="aic tabs__list">
        {tabs.map(({ label, value }) => (
          <button
            className={cn('p3 centered tabs__tab', {
              tabs__tab_active: value === activeTab,
              tabs__tab_sm: size === 'sm',
            })}
            onClick={handleChange(value)}
            key={value}
          >
            {label}
          </button>
        ))}
      </div>

      {renderedCancelBtn}
    </div>
  );
};

export default Tabs;
