import React, { FC } from 'react';

export const Sting: FC = () => (
  <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="64" height="64" rx="17" fill="#FFE3E3" fillOpacity=".4" />
    <rect
      x=".828947"
      y=".828947"
      width="62.3421"
      height="62.3421"
      rx="16.1711"
      stroke="#FFE3E3"
      strokeOpacity=".4"
      strokeWidth="1.65789"
    />
    <path
      d="m19.7734 36.1501.6066 6.2677h3.2349l17.792-17.7921-5.0545-5.0545-16.579 16.5789Z"
      fill="#fff"
    />
    <path
      d="m47.8851 21.0604-5.1162-5.1161c-.1943-.1943-.5181-.1943-.7124 0l-.7771.7124c-.1943.1942-.1943.5181 0 .7123l1.8781 1.8781-2.979 2.9143-3.6267-3.6914-1.1009-1.101c-.1943-.1942-.5181-.1942-.7124 0l-.7772.7772c-.1942.1943-.1942.5181 0 .7123l1.101 1.101-14.7009 14.7009c-1.1009 1.1009-1.6838 2.6552-1.4895 4.2742l.4533 4.08-4.2742 4.2743c-.1943.259-.1943.5828 0 .7771l.7124.7124c.1942.1943.5181.1943.7123 0l4.3391-4.2743 4.0799.4533c1.5543.1296 3.1086-.3885 4.2743-1.4895l14.7009-14.7008 1.1009 1.1009c.1943.1943.5181.1943.7124 0l.7771-.7771c.1943-.1943.1943-.5181 0-.7124l-4.7923-4.7276 2.9142-2.979 1.8781 1.8781c.1943.1942.5181.1942.7124 0l.7124-.7772c.1943-.1943.1943-.5181 0-.7124Zm-5.5048 6.2171L27.6795 41.9784c-.6476.7124-1.5543 1.0362-2.5257.9067l-3.7562-.3886-.4533-3.8209c-.1295-.9067.1943-1.8781.9066-2.5257l1.4896-1.5543 2.5904 2.5905c.1943.1942.5181.1942.7124 0l.7771-.7124c.1943-.1943.1943-.5181 0-.7772L24.83 33.1708l2.9142-2.9142 2.5905 2.5257c.1943.1943.5181.1943.7124 0l.7124-.7124c.259-.1943.259-.5181 0-.7124l-2.5257-2.5904 2.9142-2.9143 2.5905 2.5257c.1943.1943.5181.1943.7124 0l.7123-.7124c.1943-.1943.1943-.5181 0-.7124l-2.5257-2.5904 2.9143-2.9143 5.8285 5.8285Z"
      fill="#EA2A5C"
    />
  </svg>
);
