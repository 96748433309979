import React, { FC, useCallback, useMemo } from 'react';
import cn from 'classnames';
import useWindowSize from 'hooks/useWindowSize';

interface Props {
  className?: string;
  round?: number;
  eggs?: number;
  leastOne?: string;
  leastTwo?: string;
  single?: boolean;
}

const RoundCard: FC<Props> = ({
  round = 1,
  eggs = 4,
  leastOne = '10%',
  leastTwo = '< 10%',
  className,
  single,
}) => {
  const { breakpoint } = useWindowSize();

  const roundCardClassName = cn('round-card', className);

  const renderedRoundTitle = useMemo(
    () => (round > 1 ? `${round} cycles` : `${round} cycle`),
    [round],
  );

  const renderedEggBg = useMemo(() => {
    if (breakpoint === 'mobile') {
      return single
        ? `/img/rounds/single-mob.svg`
        : `/img/rounds/round-${round}-mob.svg`;
    }

    return single ? `/img/rounds/single.svg` : `/img/rounds/round-${round}.svg`;
  }, [breakpoint, round, single]);

  const getActualValue = useCallback((str: string) => {
    if (str.includes('<')) {
      return str.replace('<', '');
    }

    if (str.includes('>')) {
      return str.replace('>', '');
    }

    return str;
  }, []);

  return (
    <div className={roundCardClassName}>
      <div className="centered round-card__header">
        <h3 className="p2 round-card__title">
          If you do
          <strong className="text-semi-bold round-card__title_accent">
            {renderedRoundTitle}
          </strong>
        </h3>
      </div>
      <div className="df round-card__main">
        <div className="centered round-card__eggs round-card-eggs">
          <img
            src={renderedEggBg}
            alt={`round-egg-${round}`}
            className="round-card-eggs__ellipse"
          />
          <div className="fdc aic round-card-eggs__info">
            <span className="round-card-eggs__count">{eggs}</span>
            <span className="p4 round-card-eggs__text">Eggs</span>
          </div>
        </div>
        <div className="round-card__divider" />
        <div className="fdc round-card__info round-card-info">
          <h4 className="p3 text-semi-bold round-card-info__title">
            Live birth chance
          </h4>
          <ul className="fdc round-card-info__list">
            <li className="df round-card-info__item">
              <span
                className={cn('round-card-info__value', {
                  'round-card-info__value_more-sign': leastOne.includes('>'),
                  'round-card-info__value_less-sign': leastOne.includes('<'),
                })}
              >
                {getActualValue(leastOne)}
              </span>
              <span className="p3 round-card-info__text">
                at least 1 live birth
              </span>
            </li>
            <li className="df round-card-info__item">
              <span
                className={cn('round-card-info__value', {
                  'round-card-info__value_more-sign': leastTwo.includes('>'),
                  'round-card-info__value_less-sign': leastTwo.includes('<'),
                })}
              >
                {getActualValue(leastTwo)}
              </span>
              <span className="p3 round-card-info__text">
                at least 2 live births
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RoundCard;
