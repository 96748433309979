import React, { FC, useMemo } from 'react';
import cn from 'classnames';

import { Area, AreaChart, XAxis, YAxis } from 'recharts';
import useWindowSize from 'hooks/useWindowSize';

interface Props {
  max?: number;
  a?: number;
  b?: number;
  className?: string;
}

const ProcessChart: FC<Props> = ({ a = 18, b = 10, max = 18, className }) => {
  const { windowSize } = useWindowSize();

  const chartClassName = cn('chart', className);

  const data = useMemo(
    () =>
      windowSize.width >= 1550
        ? [
            {
              name: 'eggs',
              value: a,
            },
            {
              name: 'eggs',
              value: a - 0.5,
            },
            {
              name: 'eggs',
              value: (a + b) / 2,
            },
            {
              name: 'eggs',
              value: b + 1,
            },
            {
              name: 'eggs',
              value: b,
            },
          ]
        : [
            {
              name: 'eggs',
              value: a + 0.3,
            },
            {
              name: 'eggs',
              value: a - 0.5,
            },
            {
              name: 'eggs',
              value: (a + b) / 2,
            },
            {
              name: 'eggs',
              value: b + 1,
            },
            {
              name: 'eggs',
              value: b,
            },
          ],
    [a, b, windowSize.width],
  );

  return (
    <div className={chartClassName}>
      <AreaChart
        width={186}
        height={165}
        data={data}
        margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
      >
        <XAxis dataKey="eggs" hide />
        <YAxis hide interval={1} domain={[0, max]} />
        <defs>
          <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
            <stop offset="20%" stopColor="#fa97b1" stopOpacity={0.3} />
            <stop offset="80%" stopColor="#fff" stopOpacity={0.2} />
          </linearGradient>
        </defs>
        <Area
          type="natural"
          dataKey="value"
          strokeWidth={2}
          stroke="#FA97B1"
          fill="url(#color)"
          activeDot={false}
          dot={false}
        />
      </AreaChart>
    </div>
  );
};

export default ProcessChart;
