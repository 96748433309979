import React, { FC } from 'react';
import { plans } from 'utils/constants/plans';

const Plans: FC = () => (
  <section className="plans">
    <div className="plans__info">
      <h3 className="h2 plans__title">
        How much does
        <strong className="plans__marker">it cost?</strong>
      </h3>
      <p className="p2 plans__description">
        The cost of freezing eggs varies between clinics, regions and is also
        dependent on the desired number of eggs to freeze and the number of
        cycles it takes to achieve that number.
      </p>
      <h3 className="p2 text-semi-bold plans__subtitle plans__subtitle_with-margin">
        There are many financial solutions which cover some of these costs and
        they will be introduced as part of the consultation.
      </h3>
      <h3 className="p2 text-semi-bold plans__subtitle">Average costs</h3>
    </div>
    <div className="fww plans__cards">
      {plans.map(({ title, amount }) => (
        <div className="fdc jcc plans__card plan-card" key={title}>
          <h4 className="plan-card__title">{title}</h4>
          <span className="text-semi-bold plan-card__amount">{amount}</span>
        </div>
      ))}
    </div>
  </section>
);

export default Plans;
