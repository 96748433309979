import { Doctor, Egg, Sting, Thermometer } from 'views/icons/steps';

export const stepsList: NextStepsCard[] = [
  {
    id: '1',
    icon: <Doctor />,
    caption: 'Consultation and medical evaluation',
    content: [
      {
        text: 'The first step is to meet with a fertility specialist who will estimate your ovarian reserve (how your ovaries are likely to respond to treatment), this will involve:',
        list: [
          'Taking a medical history',
          'Ultrasound of the ovaries',
          'Bloodwork',
        ],
        accent: true,
        accentText: 'to meet with a fertility specialist',
      },
      {
        text: 'You and your fertility specialist will then discuss the results, treatment options, potential side effects, and financial planning for your journey.',
      },

      {
        text: "This is your opportunity to talk openly about what is in your heart. Don't hesitate to ask questions or share your concerns.",
        accent: true,
      },
    ],

    // TEST commit
  },
  {
    id: '2',
    icon: <Sting />,
    caption: 'Ovarian stimulation',
    content: [
      {
        title: 'What is it?',
        text: 'Controlled Ovarian Stimulation (COS) is the process of stimulating your ovaries with medication to produce multiple mature eggs (instead of one, as in the natural process).',
      },
      {
        title: 'Medication ',
        text: 'The medication is usually injected (by you, or with the help of a partner). This may sound intimidating, but most women are surprised by how eassy and painless this process is .',
      },
      {
        title: 'Personalized treatment',
        text: 'Your physician will personalize your stimulation protocol to best suit your medical profile and provide you with instructions on how to take the medication during the cycle.',
      },
      {
        title: 'Cycle Length',
        text: 'The stimulation process usually starts at the beginning of your menstrual cycle and continues for 10 to 14 days until your eggs are ready to be retrieved.',
      },
      {
        title: 'Monitoring',
        text: 'During this time, you will be monitored regularly with ultrasound and blood tests to see how you are responding. This allows yout team to  adjust your stimulation as required, and to choose the optimal timing for egg collection. ',
      },
    ],
  },
  {
    id: '3',
    icon: <Egg />,
    caption: 'Egg retrieval',
    content: [
      {
        title: 'Timing ',
        text: 'When your eggs are mature enough, you will be instructed to take a trigger medication that prepares your eggs for retrieval. About 32-36 hours afterwards, your eggs will be collected by your doctor.',
      },
      {
        title: 'Procedure',
        text: 'The retrieval procedure involved using a very thin needle with ultrasound guidance to collect the eggs. It is usually conducted in a  operating room while you are under sedation. It takes about 10-20 minuts, during which time you will not feel anything.',
        accentText:
          'It takes about 10-20 minuts, during which time you will not feel anything.',
      },
      {
        title: 'What to expect',
        text: 'Afterwards, you may feel a little weak due to the effects of the sedation, or you may feel some moderate local pain. You will be advised to go home and rest for the day. The majority of women resume normal activities the day after.',
      },
    ],
  },
  {
    id: '4',
    icon: <Thermometer />,
    caption: 'Egg freezing',
    content: [
      {
        title: 'Handle ',
        text: 'Eggs are cleaned and assessed by highly trained embryologists.',
      },
      {
        title: 'Freeze',
        text: 'After this, mature eggs are frozen in a process called cryopreservation.',
      },
      {
        title: 'Future use',
        list: [
          'Your eggs will remain frozen until you decide to continue on your journey of having a child through IVF.',
          'If you have decided to freeze embryos (fertilized eggs), your eggs will be fertilized and grown for several days before freezing.',
        ],
      },
    ],
  },
];
