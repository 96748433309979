import React, { FC } from 'react';

export const Doctor: FC = () => (
  <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="64" height="64" rx="17" fill="#FFE3E3" fillOpacity=".4" />
    <rect
      x=".828947"
      y=".828947"
      width="62.3421"
      height="62.3421"
      rx="16.1711"
      stroke="#FFE3E3"
      strokeOpacity=".4"
      strokeWidth="1.65789"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m38.6487 16.6198-1.1033 6.6813H24.3287l-1.1039-6.6813c-.0966-.5832.2892-1.1409.8839-1.2783l6.829-1.582 6.8282 1.582c.5948.1374.9788.6951.8828 1.2783Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.6741 24.079v.3008c0 3.8227 3.1981 6.9321 7.1284 6.9321 3.9309 0 7.129-3.1094 7.129-6.9321v-.3008H24.6741Zm7.1284 8.5258c-4.6636 0-8.4578-3.6896-8.4578-8.225v-.9108c0-.3766.3147-.6829.7018-.6829h15.512c.3876 0 .7023.3063.7023.6829v.9108c0 4.5354-3.7947 8.225-8.4583 8.225Z"
      fill="#EA2A5C"
      stroke="#EA2A5C"
      strokeWidth=".8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.2174 24.06H23.3876l-.8968-5.4273c-.0577-.3524.1884-.6841.5505-.7406.3627-.0565.7041.1829.7618.5353l.7176 4.3397h14.5636l1.014-6.1369c.0407-.2485-.1263-.4885-.3815-.548l-7.7955-1.8069c-.3573-.0826-.5772-.4313-.4928-.7788.0857-.347.4442-.5614.8014-.4788l7.7955 1.8069c.9278.215 1.5359 1.0984 1.3852 2.0104l-1.1932 7.225Z"
      fill="#EA2A5C"
      stroke="#EA2A5C"
      strokeWidth=".8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.8723 48.4557c0 .9314-.777 1.6872-1.7351 1.6872H18.284c-.9581 0-1.7352-.7558-1.7352-1.6872v-8.5306c0-2.4582 1.6629-4.4704 3.8756-5.1582 2.6897.5632 5.3811 1.1514 8.0708 1.1514.5808 0 1.164-.028 1.7455-.0784 2.3038-.1932 4.6076-.726 6.9115-1.2012.9885-.2041 1.9593-.1403 2.8446.1349 2.2133.6878 3.8755 2.7 3.8755 5.1582v8.5239Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.5643 39.6031c-.3651 0-.675-.2709-.7078-.6306l-.2843-3.1174c-.0347-.3803.2533-.7163.6446-.7509.3918-.0334.7369.2467.7716.627l.2849 3.1198c.0346.3803-.2546.7157-.6458.7497-.0213.0018-.0425.0024-.0632.0024Z"
      fill="#EA2A5C"
      stroke="#EA2A5C"
      strokeWidth=".5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.2594 46.9288c-.6191 0-1.1477-.4617-1.2024-1.0723l-.2782-2.6542c-.1993-2.2011 1.4806-4.1502 3.7437-4.344 2.2638-.1944 4.2681 1.4399 4.4674 3.6417l.2776 2.6535c.0286.3129-.0699.6179-.2776.8579-.2072.2406-.4982.3882-.8196.4156l-.8063.0692c-.39.034-.7357-.2479-.7703-.6282-.0347-.3803.2551-.7163.6458-.7491l.593-.051-.2594-2.447c-.1307-1.4417-1.4418-2.5104-2.926-2.3853-1.4824.1276-2.5827 1.4041-2.4521 2.8458l.2594 2.4464.5012-.0426c.3925-.0352.7364.2473.771.6276.0346.381-.2545.7164-.6458.7504l-.7145.0607c-.0358.0031-.0711.0049-.1069.0049Z"
      fill="#EA2A5C"
      stroke="#EA2A5C"
      strokeWidth=".7"
    />
    <path
      d="M16 39.6581c0-3.3308 3.1954-5.7304 6.3941-4.8018l2.3473.6815c4.4199 1.2832 9.1081 1.3214 13.5482.1105l3.3948-.9259C44.8651 33.855 48 36.2494 48 39.5463V46c0 2.7614-2.2386 5-5 5H21c-2.7614 0-5-2.2386-5-5v-6.3419Z"
      stroke="#EA2A5C"
      strokeWidth="2"
    />
  </svg>
);
