import React, { FC, ReactNode } from 'react';
import cn from 'classnames';

interface Props {
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  children?: ReactNode;
  className?: string;
  onClick?: () => void;
}

const TextButton: FC<Props> = ({
  children,
  type,
  disabled,
  onClick,
  className,
}) => {
  const buttonClassName = cn(
    'p3 centered text-semi-bold centered text-btn',
    className,
  );

  return (
    <button
      className={buttonClassName}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default TextButton;
