import React from 'react';
import { nanoid } from 'nanoid';

import { Facebook, Insta, LinkedIn, Web } from 'views/icons/socials';

export const ROUTES = {
  home: '/',
  form: '/form',
  results: '/results',
  next: '/next-steps',
};

export const SOCIAL_ROUTES: SocialLink[] = [
  { id: nanoid(5), route: 'https://www.embryonics.me/', icon: <Web /> },
  {
    id: nanoid(5),
    route: 'https://www.linkedin.com/company/embryonics/',
    icon: <LinkedIn />,
  },
  {
    id: nanoid(5),
    route: 'https://www.facebook.com/embryonics.me/?ti=as/',
    icon: <Facebook />,
  },
  {
    id: nanoid(5),
    route: 'https://www.instagram.com/embryonics.ltd/',
    icon: <Insta />,
  },
];
