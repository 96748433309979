import React, { FC } from 'react';
import cn from 'classnames';
import { nanoid } from 'nanoid';

export interface Props {
  total?: number;
  progress: number;
  className?: string;
}

const ProgressBar: FC<Props> = ({ total = 15, progress, className }) => {
  const progressBarClassName = cn('aic progress-bar', className);

  return (
    <div className={progressBarClassName}>
      {Array(total)
        .fill(0)
        .map((_, index) => (
          <span
            className={cn('progress-bar__dot', {
              'progress-bar__dot_active': index + 1 <= progress,
            })}
            key={nanoid(5)}
          />
        ))}
    </div>
  );
};

export default ProgressBar;
