import React, { FC } from 'react';

export const Facebook: FC = () => (
  <svg viewBox="0 0 24 24" stroke="#fff" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.705 5.387a.392.392 0 0 0-.392-.393H13.35a3.662 3.662 0 0 0-3.662 3.663v2.441h-1.57a.392.392 0 0 0-.392.392v2.616c0 .217.176.393.392.393h1.57v3.967c0 .217.175.392.392.392h2.616a.392.392 0 0 0 .392-.392v-3.967h1.57c.18 0 .337-.123.38-.298l.655-2.616a.392.392 0 0 0-.381-.487h-2.224V8.657c0-.145.117-.262.262-.262h1.962a.392.392 0 0 0 .392-.392V5.387Z"
      fill="none"
      strokeWidth=".785"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
