import usePopupsContainer from 'hooks/usePopupsContainer';
import React, { FC, useRef } from 'react';
import CalculatorForm from 'views/features/CalculatorForm';
import AMHModal from 'views/features/modals/AMHModal';
import LoadingModal from 'views/features/modals/LoadingModal';
import ResultModal from 'views/features/modals/ResultModal';
import Layout from 'views/hoc/Layout';

const CalculatorFormPage: FC = () => {
  const formRef = useRef<HTMLDivElement>(null);

  const {
    amhModalProps,
    controller,
    openAmhModal,
    resultModalProps,
    loadingModalProps,
    toggleLoadingModal,
    openResultModal,
  } = usePopupsContainer(formRef.current);
  return (
    <Layout>
      <CalculatorForm
        openAmhModal={openAmhModal}
        amhModalIsOpen={amhModalProps.active}
        setLoading={toggleLoadingModal}
        openResultModal={openResultModal}
        controller={controller}
      />
      <AMHModal {...amhModalProps} ref={formRef} />
      <ResultModal {...resultModalProps} />
      <LoadingModal {...loadingModalProps} />
    </Layout>
  );
};

export default CalculatorFormPage;
