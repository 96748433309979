import React, { FC, ReactNode, useCallback } from 'react';
import cn from 'classnames';
import useWindowSize from 'hooks/useWindowSize';
import Tooltip from '../Tooltip';

interface Props {
  className?: string;
  age: string;
  weight: string;
  height: string;
  origins?: string[];
  amh?: string;
}

const UserInfo: FC<Props> = ({
  className,
  age,
  weight,
  height,
  origins,
  amh,
}) => {
  const userInfoClassName = cn('aifs fww user-info', className);

  const {
    windowSize: { width },
    breakpoint,
  } = useWindowSize();

  const renderTooltip = useCallback(
    (
      node: ReactNode,
      visible?: boolean,
      tooltipText?: string,
      position?:
        | 'left'
        | 'right'
        | 'top-left'
        | 'top-right'
        | 'bottom-left'
        | 'bottom-right',
    ) =>
      visible ? (
        <Tooltip
          position={position}
          text={tooltipText}
          wrapClassName="user-info__tooltip"
          className="user-info__tooltip-content"
        >
          {node}
        </Tooltip>
      ) : (
        node
      ),
    [],
  );

  return (
    <div className={userInfoClassName}>
      <div className="user-info__wrapper user-info__wrapper_small">
        <div className="df p3 user-info__item user-info__item_small">
          <span className="user-info__title">Age</span>
          <span className="user-info__value">{age}</span>
        </div>
        <div className="df p3 user-info__item user-info__item_small">
          <span className="user-info__title">Height</span>
          {renderTooltip(
            <span className="user-info__value">{height}</span>,
            breakpoint === 'mobile',
            height,
            'top-left',
          )}
          {/* <span className="user-info__value">{height}</span> */}
        </div>
        <div className="df p3 user-info__item user-info__item_small">
          <span className="user-info__title">Weight</span>
          {renderTooltip(
            <span className="user-info__value">{weight}</span>,
            breakpoint === 'mobile',
            weight,
            'top-left',
          )}
          {/* <span className="user-info__value">{weight}</span> */}
        </div>
      </div>
      <div className="user-info__wrapper user-info__wrapper_big">
        <div className="df p3 user-info__item">
          <span className="user-info__title user-info__title_big">
            Ethnicity
          </span>
          {renderTooltip(
            <span className="user-info__value user-info__value_big">
              {origins?.join(', ') || '-'}
            </span>,
            true,
            origins?.join(', '),
            width >= 768 ? 'top-left' : 'top-right',
          )}
          {/* <span className="user-info__value user-info__value_big">
            {origins?.join(', ') || '-'}
          </span> */}
        </div>
        <div className="df p3 user-info__item">
          <span className="user-info__title user-info__title_big">
            AMH level
          </span>
          {renderTooltip(
            <span className="user-info__value">{amh || '-'}</span>,
            breakpoint === 'mobile',
            amh,
            'top-right',
          )}
          {/* <span className="user-info__value">{amh || '-'}</span> */}
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
