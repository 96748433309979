/* eslint-disable react/no-unescaped-entities */
import React, { FC } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { SuccessIcon } from 'views/icons';

import Button from 'views/components/Button';

interface Props {
  active?: boolean;
  icon?: JSX.Element;
  heading?: string;
  text?: string;
  link?: string;
  btnText?: string;

  onClose?: () => void;
}

const ResultModal: FC<Props> = ({
  active,
  icon = <SuccessIcon />,
  heading = 'Thank you!',
  text = 'We are connecting you with the top nearest clinic and they will contact you within the next 48 hours for further details.',
  link,
  btnText = 'Close',
  onClose,
}) => {
  const resultModalClassName = cn('result-modal', {
    'result-modal_active': active,
  });

  const renderedLink = link && (
    <Link
      to={link}
      className="p3 centered text-semi-bold centered text-btn result-modal__link"
    >
      Contact support
    </Link>
  );

  return (
    <div className={resultModalClassName}>
      <div className="amh-modal__overlay" onClick={onClose} />
      <div className="centered result-modal__container">
        <div className="aic fdc result-modal__content">
          <span className="centered result-modal__img">{icon}</span>
          <h3 className="h2 result-modal__heading">{heading}</h3>
          <p className="p2 result-modal__text">{text}</p>
          <Button className="result-modal__btn" onClick={onClose} white>
            {btnText}
          </Button>
          {renderedLink}
        </div>
      </div>
    </div>
  );
};

export default ResultModal;
