import React, { FC } from 'react';

export const Egg: FC = () => (
  <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="64" height="64" rx="17" fill="#FFE3E3" fillOpacity=".4" />
    <rect
      x=".828947"
      y=".828947"
      width="62.3421"
      height="62.3421"
      rx="16.1711"
      stroke="#FFE3E3"
      strokeOpacity=".4"
      strokeWidth="1.65789"
    />
    <path
      d="M41 28.1899c-4.2905-2.476-11.0328 6.6662-4.9035 12.3798-6.1293 1.238-14.3024-.0553-14.0925-5.7136.062-1.6726.608-5.4282.608-5.4282l3.1265-4.8567L34.1428 22l3.1796.619 1.2259 1.238L41 28.1899Z"
      fill="#fff"
    />
    <circle
      cx="32.0393"
      cy="32.2419"
      r="15.7005"
      stroke="#EA2A5C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41 32c0-4.9706-4.0294-9-9-9s-9 4.0294-9 9 4.0294 9 9 9c3.3313 0 6.2398-1.8099 7.796-4.5"
      stroke="#EA2A5C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28 31c0-1.6569 1.3431-3 3-3"
      stroke="#EA2A5C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
