import React, { FC } from 'react';

export const Syringe: FC = () => (
  <svg viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m3.74 12.464.36 3.722h1.921L16.585 5.622 13.584 2.62 3.74 12.464Z"
      fill="#fff"
    />
    <path
      d="M20.431 3.505 17.394.467a.302.302 0 0 0-.423 0l-.462.423a.302.302 0 0 0 0 .423l1.115 1.115-1.768 1.73-2.154-2.191-.653-.654a.302.302 0 0 0-.423 0l-.462.461a.302.302 0 0 0 0 .423l.654.654-8.729 8.729c-.653.653-1 1.576-.884 2.537l.269 2.423-2.538 2.538c-.115.154-.115.346 0 .461l.423.423a.302.302 0 0 0 .423 0l2.576-2.538 2.423.27a3.287 3.287 0 0 0 2.538-.885l8.728-8.729.654.654a.302.302 0 0 0 .423 0l.461-.461a.302.302 0 0 0 0-.423L16.74 5.043l1.73-1.77 1.115 1.116a.302.302 0 0 0 .423 0l.423-.461a.302.302 0 0 0 0-.423Zm-3.268 3.691-8.729 8.729a1.68 1.68 0 0 1-1.5.538l-2.23-.23-.269-2.27a1.717 1.717 0 0 1 .539-1.499l.884-.923 1.538 1.538a.302.302 0 0 0 .423 0l.461-.423c.116-.115.116-.307 0-.461l-1.538-1.5 1.73-1.73 1.539 1.5a.302.302 0 0 0 .423 0l.423-.424c.154-.115.154-.307 0-.422l-1.5-1.539 1.73-1.73 1.539 1.5a.302.302 0 0 0 .423 0l.423-.423a.302.302 0 0 0 0-.423l-1.5-1.538 1.73-1.73 3.46 3.46Z"
      fill="#EA2A5C"
    />
  </svg>
);
