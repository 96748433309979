import React, { FC } from 'react';

export const LinkedIn: FC = () => (
  <svg viewBox="0 0 24 24" stroke="#fff" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.079 5.92a.4.4 0 0 0 0-.018c-.021-.479-.178-.904-.488-1.245-.327-.36-.77-.526-1.282-.526-.575 0-1.08.15-1.466.492h0a1.67 1.67 0 0 0-.577 1.296c0 .495.174.929.516 1.275h0l.001.002c.358.358.829.52 1.37.52h.016c.548 0 1.027-.16 1.388-.52h0c.349-.347.522-.782.522-1.277ZM4.836 9.633a.392.392 0 0 0-.393.392v8.133c0 .217.176.393.393.393H7.73a.392.392 0 0 0 .392-.393v-8.133a.392.392 0 0 0-.392-.392H4.836Zm14.956 8.918a.392.392 0 0 0 .393-.393v-4.663c0-.659-.087-1.248-.276-1.757h0a3.621 3.621 0 0 0-.787-1.279h0a3.104 3.104 0 0 0-1.211-.774h0a4.354 4.354 0 0 0-1.452-.238c-.408 0-.78.047-1.116.147h-.002a3.36 3.36 0 0 0-.842.375h0c-.094.06-.182.123-.264.188v-.132a.392.392 0 0 0-.392-.392H10.93a.392.392 0 0 0-.392.392l.003.266.005.306h0v.004c.007.28.011.618.011 1.015v2.76c0 1.143-.007 2.404-.02 3.78a.392.392 0 0 0 .393.395h2.913a.392.392 0 0 0 .392-.392v-4.544c0-.115.005-.219.014-.311a.806.806 0 0 1 .042-.194c.078-.18.212-.357.414-.529h.002c.153-.131.379-.216.718-.216.446 0 .677.135.807.328.17.254.274.614.274 1.116v4.35c0 .216.175.392.392.392h2.894Z"
      fill="none"
      strokeWidth=".785"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
