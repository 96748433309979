import React, { FC } from 'react';

export const Thermometer: FC = () => (
  <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="64" height="64" rx="17" fill="#FFE3E3" fillOpacity=".4" />
    <rect
      x=".828947"
      y=".828947"
      width="62.3421"
      height="62.3421"
      rx="16.1711"
      stroke="#FFE3E3"
      strokeOpacity=".4"
      strokeWidth="1.65789"
    />
    <path
      d="M27.8733 36.2136c-1.699 3.5224 5.8921 8.2974 10.0329 3.1384 1.3323 4.7534.7909 11.2636-3.6763 11.4286-1.3204.0487-4.3091-.1621-4.3091-.1621l-4.0062-2.1782L23.4 41.9735l.3018-2.5395.9032-1.0373 3.2683-2.1831ZM28.5718 16H35v13h-7v-6.1636c0-1.907.1364-3.8116.4081-5.6991L28.5718 16Z"
      fill="#fff"
    />
    <path
      d="M27.5342 36c-1.5252.8805-2.7171 2.2397-3.3911 3.8667-.6739 1.6271-.7921 3.431-.3363 5.1321.4558 1.701 1.4601 3.2042 2.8573 4.2762 1.3971 1.0721 3.109 1.6532 4.8701 1.6532 1.7611 0 3.4729-.5811 4.8701-1.6532 1.3971-1.072 2.4015-2.5752 2.8573-4.2762.4558-1.7011.3376-3.505-.3364-5.1321-.6739-1.627-1.8659-2.9862-3.391-3.8667V19c0-1.0609-.4214-2.0783-1.1716-2.8284C33.6125 15.4214 32.595 15 31.5342 15c-1.0609 0-2.0783.4214-2.8284 1.1716-.7502.7501-1.1716 1.7675-1.1716 2.8284v17ZM32 28.9091h3.5338M32.2607 24h3.2729M43 22h6.4663"
      stroke="#EA2A5C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.4428 42.8182c0 1.5062-1.221 2.7273-2.7272 2.7273-1.5063 0-2.7273-1.2211-2.7273-2.7273s1.221-2.7273 2.7273-2.7273"
      stroke="#EA2A5C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
