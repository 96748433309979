import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';

import { Check, Question } from 'views/icons';

import Tooltip from '../Tooltip';

interface Props {
  checked?: boolean;
  disabled?: boolean;
  label?: string;
  className?: string;
  filled?: boolean;
  transparent?: boolean;
  error?: boolean;
  tooltipText?: string;
  errorText?: string;

  onChange?: (value: boolean) => void;
}

const Checkbox = forwardRef<HTMLInputElement, Props>(
  (
    {
      disabled = false,
      checked = false,
      label,
      filled,
      transparent,
      error,
      className,
      tooltipText,
      errorText = 'Please check this box',
      onChange,
    },
    ref,
  ) => {
    const [isChecked, setChecked] = useState(checked);
    const [tooltipIsVisible, setTooltipIsVisible] = useState<boolean>(false);

    const checkboxClassName = cn(
      'checkbox',
      {
        aifs: !!label && !filled,
        aic: !!label && filled,
        checkbox_filled: filled,
        checkbox_active: isChecked && filled,
        checkbox_transparent: transparent && !filled,
        checkbox_error: error && !filled,
        checkbox_events_none: tooltipIsVisible,
      },
      className,
    );

    const handleChange = useCallback(() => {
      if (tooltipIsVisible) {
        return;
      }
      const newValue = !isChecked;

      setChecked(newValue);

      if (onChange) onChange(newValue);
    }, [isChecked, onChange, tooltipIsVisible]);

    const getTooltipVisible = useCallback(
      (visible: boolean) => setTooltipIsVisible(visible),
      [],
    );

    useEffect(() => setChecked(checked), [checked]);

    const renderedLabel = label && (
      <span className="p2 checkbox__label" key="checkbox-label">
        {label}
      </span>
    );

    const renderedTooltip = tooltipText && (
      <Tooltip
        text={tooltipText}
        wrapClassName="checkbox__tooltip"
        getVisible={getTooltipVisible}
        position="top-right"
      >
        <span className="checkbox__tooltip-icon">
          <Question />
        </span>
      </Tooltip>
    );

    const renderedErrorText = error && errorText && (
      <span className="p3 checkbox__error">{errorText}</span>
    );

    return (
      <label className={checkboxClassName}>
        <input
          ref={ref}
          type="checkbox"
          className={cn('checkbox__real', { checkbox__real_filled: filled })}
          disabled={disabled}
          checked={isChecked}
          onChange={handleChange}
          key="checkbox"
        />
        <span className="checkbox__custom centered">
          <span className="centered checkbox__icon">
            <Check />
          </span>
        </span>
        {renderedLabel}
        {renderedTooltip}
        {renderedErrorText}
      </label>
    );
  },
);

export default Checkbox;
