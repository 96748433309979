import React, { FC } from 'react';

export const Drops: FC = () => (
  <svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.628 3.179 8.449 1 3.185 6.274a7.454 7.454 0 1 0 11.68 9.076"
      stroke="#EA2A5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m15.624 2.453 2.979 2.978a4.21 4.21 0 1 1-5.952 0l2.973-2.978Z"
      fill="#fff"
      stroke="#EA2A5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.594 15.634c-.83 0-1.64-.25-2.33-.716a4.233 4.233 0 0 1-1.544-1.905 4.292 4.292 0 0 1-.238-2.452"
      stroke="#EA2A5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
