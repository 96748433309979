import React, { FC } from 'react';

export const ChevronLeft: FC = () => (
  <svg viewBox="0 0 24 24" stroke="#EA2A5C" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.8789 2.96805 6.9681 11.8789l9.9108 8.9108"
      fill="none"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
