import React, { FC, useCallback } from 'react';
import cn from 'classnames';

import { ChevronDown } from 'views/icons';
import TextButton from '../TextButton';

interface Props extends NextStepsCard {
  openCard: string | null;
  onChange?: (id: string) => void;
  nextCardId: string;
  last?: boolean;
}

const StepCard: FC<Props> = ({
  id,
  icon,
  caption,
  content,
  openCard,
  nextCardId,
  last,
  onChange,
}) => {
  const cardClassName = cn('step-card', {
    'step-card_active': id === openCard,
  });

  const setAccentText = useCallback(
    (str?: string, accentStr?: string, accent?: boolean) => {
      if (str) {
        if (accentStr) {
          const accentStrIndex = str.indexOf(accentStr);

          return (
            <p className="step-card-content__text">
              {str.substring(0, accentStrIndex)}
              <strong className="step-card-content__text_accent">
                {accentStr}
              </strong>
              {str.substring(accentStrIndex, str.length).split(accentStr)}
            </p>
          );
        }

        return (
          <p
            className={cn('step-card-content__text', {
              'step-card-content__text_accent': accent,
            })}
          >
            {str}
          </p>
        );
      }

      return null;
    },
    [],
  );

  return (
    <div className={cardClassName}>
      <button className="aic step-card__header" onClick={() => onChange?.(id)}>
        <span className="centered step-card__icon">{icon}</span>
        <h3 className="text-semi-bold step-card__title">{caption}</h3>
        <span className="centered step-card__arrow">
          <ChevronDown />
        </span>
      </button>
      <div className="step-card__content step-card-content">
        <div className="step-card-content__wrapper">
          {content.map(({ title, text, list, accent, accentText }) => (
            <div className="step-card-content__item" key={title || text}>
              {title && (
                <h3 className="text-semi-bold step-card-content__title">
                  {title}
                </h3>
              )}
              {setAccentText(text, accentText, accent)}
              {list && list.length > 0 && (
                <ul
                  className={cn('step-card-content__list', {
                    'step-card-content__list_accent': accent,
                  })}
                >
                  {list.map((item) => (
                    <li
                      className={cn('step-card-content__list-item', {
                        'step-card-content__list-item_accent': accent,
                      })}
                      key={item}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
          <div className="step-card-content__divider" />
          <TextButton
            className="step-card-content__btn"
            onClick={() => onChange?.(nextCardId)}
          >
            {last ? 'Close' : 'Next'}
          </TextButton>
        </div>
      </div>
    </div>
  );
};

export default StepCard;
