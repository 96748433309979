import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { ROUTES } from 'utils/constants/routes';

import { Logo } from 'views/icons';

import Footer from 'views/components/Footer';
import useWindowSize from 'hooks/useWindowSize';
import OrientationStub from 'views/features/OrientationStub';
import Button from 'views/components/Button';

interface Props {
  onStart?: () => void;
}

const MobileIntroPage: FC<Props> = ({ onStart }) => {
  const { breakpoint, isHorizontal, isMobile } = useWindowSize();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, []);

  const renderedEllipse =
    breakpoint === 'mobile' ? (
      <svg
        width="76"
        height="102"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="intro__ellipse"
      >
        <path
          d="M14.39 73.883c18.692 16.665 52.313 31.303 75.93 26.983 68.041-12.445 58.962-116.583-8.098-120.323C38.3-21.902-.066-1.46.058 42.154.098 55.666 5.68 66.118 14.39 73.883Z"
          fill="#FFE3E3"
        />
      </svg>
    ) : (
      <svg
        width="241"
        height="170"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="intro__ellipse"
      >
        <path
          d="M34.53 103.919c44.499 39.674 124.54 74.522 180.765 64.239 161.984-29.627 140.37-277.548-19.279-286.453C91.447-124.115.114-75.449.409 28.382c.096 32.169 13.383 57.05 34.12 75.537Z"
          fill="#FFE3E3"
        />
      </svg>
    );

  return (
    <div className="intro">
      {renderedEllipse}
      <img src="/img/banner-mobile.svg" alt="banner" className="intro__img" />
      <div className="fdc jcsb container intro__container">
        <div className="intro__header">
          <Link to={ROUTES.home} className="intro__logo">
            <Logo />
          </Link>
          <h1 className="h1 intro__heading">
            Egg freezing calculator<strong className="intro__dot">.</strong>
          </h1>
          <h2 className="h2 intro__title">
            Considering going through fertility preservation?
          </h2>
          <p className="p2 intro__text">
            This calculator predicts of the number of eggs that will be
            retrieved and the likelihood of giving a live birth, so you get a
            sense of how many rounds you will need to do according to your
            goals.
          </p>
        </div>
        <div className="fdc aic intro__footer">
          <Button onClick={onStart} className="intro__btn">
            Get Started
          </Button>
          <Footer />
        </div>
      </div>
      <OrientationStub isLandscape={isHorizontal} isMobile={isMobile} />
    </div>
  );
};

export default MobileIntroPage;
