import React, { FC } from 'react';

export const ErrorIcon: FC = () => (
  <svg viewBox="0 0 347 213" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M232.196 58.1856c17.332 22.1768 29.276 59.7484 19.356 83.9344-28.579 69.679-157.4299 44.395-149.333-26.699 5.31-46.5643 38.258-83.8236 92.924-77.1031 16.935 2.0841 28.977 9.5331 37.053 19.8677Z"
      fill="#FFEDF2"
      fillOpacity=".7"
    />
    <mask
      id="a-error"
      maskUnits="userSpaceOnUse"
      x="105"
      y="62"
      width="103"
      height="103"
    >
      <path fill="#C4C4C4" d="M105 62h103v103H105z" />
    </mask>
    <g mask="url(#a-error)">
      <g filter="url(#b-error)">
        <path
          d="m154.958 87.6041 35.811 32.5389c2.893 2.769 2.994 7.358.225 10.251-.887.927-2.005 1.602-3.239 1.956l-48.909 14.024c-3.849 1.104-7.864-1.122-8.968-4.97-.354-1.234-.374-2.54-.057-3.784l13.097-46.5636c.986-3.8808 4.932-6.2274 8.812-5.2413 1.212.308 2.324.9243 3.228 1.789Z"
          fill="#FF5F88"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M157.498 118.977c.961-.275 1.869-.163 2.73.314.872.485 1.444 1.2 1.718 2.157.274.955.168 1.865-.312 2.748-.476.861-1.188 1.437-2.148 1.712-.96.276-1.869.164-2.729-.313-.866-.497-1.438-1.213-1.712-2.169s-.168-1.866.305-2.736c.477-.861 1.188-1.437 2.148-1.713Zm-3.049-3.595c-.005.001-.01-.001-.012-.006-.715-1.963-1.236-3.321-1.553-4.067l-3.318-7.274c-.251-.59-.431-1.077-.538-1.449-.178-.621-.19-1.27-.039-1.914.152-.643.459-1.2073.911-1.6889.451-.4908.981-.8088 1.565-.9762.593-.17 1.214-.1721 1.865.0029.659.1635 1.221.4885 1.703.9606.472.4752.802 1.0218.983 1.6526.14.488.252 1.128.349 1.928.439 3.806.827 6.685 1.165 8.647.174.962.389 1.996.657 3.107 0 .003-.001.006-.004.006-.003.001-.004.004-.003.007l.023.081c.299 1.042-.295 2.133-1.321 2.427-1.017.292-2.099-.319-2.398-1.361l-.022-.076c-.002-.005-.007-.009-.013-.007Z"
        fill="#FFD8E2"
      />
      <path
        d="m130.75 94.4259-7.185-1.1296M146.118 77.4569 144.5 66.5M135.849 83.5432l-17.348-20.0433"
        stroke="#D6315C"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <circle
      cx="178.428"
      cy="75.4284"
      r="7.86147"
      transform="rotate(-13 178.428 75.4284)"
      fill="#FFAAC0"
    />
    <defs>
      <filter
        id="b-error"
        x="129.598"
        y="85.5901"
        width="63.4092"
        height="61.0669"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-4" dy="-4" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix values="0 0 0 0 0.933295 0 0 0 0 0.252752 0 0 0 0 0.427141 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_182_18458" />
      </filter>
    </defs>
  </svg>
);
