import React, { FC, ReactNode } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import Spiner from '../Spiner';

interface Props {
  rounded?: boolean;
  white?: boolean;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  children?: ReactNode;
  className?: string;
  route?: string;
  active?: boolean;
  loading?: boolean;

  onClick?: () => void;
}

const Button: FC<Props> = ({
  children,
  rounded,
  white,
  type,
  disabled,
  route,
  active,
  loading,
  onClick,
  className,
}) => {
  const buttonClassName = cn(
    'p1 text-semi-bold centered btn',
    { btn_white: white, btn_rounded: rounded, btn_active: active },
    className,
  );

  const renderedContent = loading ? (
    <Spiner className="btn__spiner" />
  ) : (
    children
  );

  const renderedButton = route ? (
    <Link to={route} className={buttonClassName}>
      {children}
    </Link>
  ) : (
    <button
      className={buttonClassName}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {renderedContent}
    </button>
  );

  return renderedButton;
};

export default Button;
