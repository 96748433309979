import React, { FC, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';

interface Props {
  className?: string;
  unit?: string;
  units?: { label: string; value: string }[];
  size?: 'md' | 'lg';

  onChange?: (checked: string) => void;
}

const initialSwitchValues = [
  { label: 'sm', value: 'sm' },
  { label: 'ft', value: 'ft' },
];

const Switch: FC<Props> = ({
  className,
  unit,
  size,
  units = initialSwitchValues,
  onChange,
}) => {
  const [activeValue, setActiveValue] = useState(unit || units[0].value);

  const switchClassName = cn(
    'aic switch',
    {
      switch_active: activeValue === units[1].value,
      switch_md: size === 'md',
      switch_lg: size === 'lg',
    },
    className,
  );

  useEffect(() => {
    if (unit) {
      setActiveValue(unit);
    } else {
      setActiveValue(units[0].value);
    }
  }, [unit, units]);

  const handleSwitchToggle = useCallback(() => {
    const actualActiveValue =
      activeValue === units[0].value ? units[1].value : units[0].value;

    setActiveValue(actualActiveValue);
    onChange?.(actualActiveValue);
  }, [activeValue, onChange, units]);

  return (
    <button className={switchClassName} onClick={handleSwitchToggle}>
      <div className="aic jcsb switch__inner">
        {units.map(({ label, value }) => (
          <span
            className={cn('centered p4 switch__item', {
              switch__item_active: value === activeValue,
            })}
            key={value}
          >
            {label}
          </span>
        ))}
      </div>
      <span className="switch__toggler" />
    </button>
  );
};

export default Switch;
