import React, { FC } from 'react';

export const Logo: FC = () => (
  <img
    src={`${process.env.PUBLIC_URL}/RheaLogo.svg`}
    alt="Logo"
    style={{
      width: '187px',
      height: '47px',
      display: 'block',
      margin: '0 auto',
      marginLeft: '-60px', // Adjust this value to shift the logo to the left
    }}
  />
);
