/* eslint-disable react/no-unescaped-entities */
import React, { forwardRef, useCallback, useEffect, useState } from 'react';

import { stepsList } from 'utils/constants/steps';

import { ChevronLeft } from 'views/icons';

import Button from 'views/components/Button';
import StepCard from 'views/components/StepCard';
import Plans from '../Plans';
import SubscriptionForm from '../SubscriptionForm';

interface Props {
  cards?: NextStepsCard[];
  loading?: boolean;
  subscriptionFormProps: FormProps;

  handleBack?: () => void;
}

const NextSteps = forwardRef<HTMLHeadingElement, Props>(
  ({ loading, subscriptionFormProps, cards = stepsList, handleBack }, ref) => {
    const [openCard, setOpenCard] = useState<string | null>(null);

    useEffect(() => {
      if (typeof window !== 'undefined') {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    }, []);

    const handleCardChange = useCallback(
      (id: string) => setOpenCard(openCard === id ? null : id),
      [openCard],
    );

    return (
      <section className="next-steps">
        <div className="next-steps__main">
          <h3 className="h2 next-steps__title" ref={ref}>
            So... what’s
            <strong className="next-steps__marker">next?</strong>
          </h3>
          <p className="p2 next-steps__description">
            Fertility preservation (egg freezing) is not a single procedure, but
            rather a process. Understanding the next steps could help you decide
            if this is something you are interested in, and help you prepare for
            the process.
          </p>
          <h3 className="p2 text-semi-bold next-steps__subtitle">
            These are the next steps in the egg freezing journey
          </h3>
          <div className="fdc next-steps__cards">
            {cards?.map((card, index) => (
              <StepCard
                {...card}
                key={card.id}
                onChange={handleCardChange}
                openCard={openCard}
                nextCardId={
                  cards[index + 1] ? cards[index + 1].id : cards[index].id
                }
                last={index === cards.length - 1}
              />
            ))}
          </div>
          <Plans />
          <Button
            rounded
            className="next-steps__back"
            white
            onClick={handleBack}
            disabled={loading}
          >
            <span className="next-steps__icon">
              <ChevronLeft />
            </span>
          </Button>
        </div>
        <SubscriptionForm {...subscriptionFormProps} />
      </section>
    );
  },
);

export default NextSteps;
