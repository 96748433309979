import React, { FC } from 'react';

export const Close: FC = () => (
  <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m3.525 3.525 4.95 4.95M3.525 8.475l4.95-4.95"
      stroke="#fff"
      strokeWidth="1.143"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
