import React, { FC } from 'react';
import cn from 'classnames';

import Button from 'views/components/Button';
import Input from 'views/components/Input';
import Checkbox from 'views/components/Checkbox';

interface Props extends FormProps {
  className?: string;
}

const SubscriptionForm: FC<Props> = ({
  checked,
  fields,
  checkError,
  loading,
  onSubmit,
  onCheck,
  className,
}) => {
  const voucherFormClassName = cn('subscription-form', className);

  return (
    <section className={voucherFormClassName}>
      <div className="subscription-form__container">
        <div className="subscription-form__info">
          <h2 className="h2 text-medium subscription-form__heading">
            Let us connect you to the most advanced clinics
          </h2>
          <h4 className="text-semi-bold subscription-form__text subscription-form__text_mb">
            Our AI tools are designed to improve fertility treatments.
          </h4>
          <h4 className="text-semi-bold subscription-form__text">
            Leave your details below, and we&apos;ll ensure you continue your
            journey with one of the advanced clinics that work with us.
          </h4>
        </div>
        <div className="subscription-form__form">
          {fields.map(
            ({
              name,
              value,
              onChange,
              onFocus,
              onBlur,
              label,
              error,
              errorText,
              disabled,
              type,
              invalidChars,
            }) => (
              <Input
                value={value}
                key={name}
                error={error}
                disabled={disabled}
                label={label}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                required
                errorText={errorText}
                wrapClassName="subscription-form__field"
                invalidChars={invalidChars}
                type={type}
              />
            ),
          )}
          <Checkbox
            transparent
            disabled={loading}
            checked={checked}
            error={checkError}
            onChange={onCheck}
            className="subscription-form__checkbox"
            label="By submitting your contact details, you agree that
                Embryonics may collect, use and disclose your
                contact details to third parties and approve
                receiving relevant marketing materials. We do not
                share any data aside from your name and email
                address. Good luck on your journey!"
          />
          <Button
            className="subscription-form__btn"
            onClick={onSubmit}
            disabled={loading}
            loading={loading}
          >
            Get in touch
          </Button>
        </div>
      </div>
    </section>
  );
};

export default SubscriptionForm;
