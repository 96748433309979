import React, { FC, useCallback, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { ROUTES } from 'utils/constants/routes';

import useWindowSize from 'hooks/useWindowSize';

import CalculatorFormPage from 'views/pages/CalculatorFormPage';
import MobileIntroPage from 'views/pages/MobileIntroPage';
import NextStepsPage from 'views/pages/NextStepsPage';
import ResultsPage from 'views/pages/ResultsPage';
import useAnalyticsContainer from 'hooks/useAnalyticsContainer';

const App: FC = () => {
  const [intro, setIntro] = useState<boolean>(true);

  const { state } = useLocation() as {
    state?: { userData?: UserData; results?: RoundResult[] };
  };

  useAnalyticsContainer();

  const { breakpoint } = useWindowSize();

  const handleStart = useCallback(() => setIntro(false), []);

  const handleResize = useCallback(() => {
    if (typeof window !== 'undefined') {
      if (breakpoint === 'desk' || state?.userData?.age) {
        setIntro(false);
      } else setIntro((prev) => prev);
    }
  }, [breakpoint, state?.userData?.age]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);

      handleResize();
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, [handleResize]);

  return (
    <Routes>
      <Route
        index
        element={
          !intro ? (
            <CalculatorFormPage />
          ) : (
            <MobileIntroPage onStart={handleStart} />
          )
        }
      />
      <Route
        path={ROUTES.results}
        element={
          !state?.userData?.age ||
          !state?.userData?.weight ||
          !state?.results ||
          state?.results.length === 0 ? (
            <Navigate replace to={ROUTES.home} />
          ) : (
            <ResultsPage />
          )
        }
      />
      <Route
        path={ROUTES.next}
        element={
          !state?.userData?.age ||
          !state?.userData?.weight ||
          !state?.results ||
          state?.results.length === 0 ? (
            <Navigate replace to={ROUTES.home} />
          ) : (
            <NextStepsPage />
          )
        }
      />
    </Routes>
  );
};
export default App;
