import React, { FC } from 'react';

export const Frozen: FC = () => (
  <svg viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16.163" cy="14.178" r="6.302" fill="#fff" />
    <path
      d="M9.86 21.368a.514.514 0 0 1-.517-.51V1.194c0-.282.231-.51.517-.51.286 0 .517.228.517.51v19.662a.514.514 0 0 1-.517.511Z"
      fill="#EA2A5C"
      stroke="#EA2A5C"
      strokeWidth=".295"
    />
    <path
      d="M9.86 6.853a.52.52 0 0 1-.366-.15L6.397 3.645a.507.507 0 0 1 0-.724c.202-.2.53-.2.732 0L9.86 5.618l2.731-2.697c.203-.2.53-.2.732 0 .203.2.203.524 0 .724l-3.097 3.058c-.1.1-.234.15-.366.15ZM12.956 19.28a.519.519 0 0 1-.366-.15l-2.73-2.697-2.732 2.697c-.202.2-.53.2-.732 0a.507.507 0 0 1 0-.723l3.096-3.06a.537.537 0 0 1 .732 0l3.098 3.06c.202.2.202.523 0 .723a.52.52 0 0 1-.366.15ZM1.241 16.451a.52.52 0 0 1-.449-.256.507.507 0 0 1 .19-.698l17.241-9.83a.52.52 0 0 1 .707.187.507.507 0 0 1-.19.698L1.5 16.382a.516.516 0 0 1-.259.07Z"
      fill="#EA2A5C"
      stroke="#EA2A5C"
      strokeWidth=".295"
    />
    <path
      d="M4.62 18.057a.51.51 0 0 1-.5-.644l1-3.684-3.73-.987a.51.51 0 0 1-.366-.627.52.52 0 0 1 .633-.361l4.23 1.119a.507.507 0 0 1 .366.626l-1.132 4.178a.517.517 0 0 1-.5.379ZM18.2 10.314a.502.502 0 0 1-.135-.018l-4.23-1.119a.507.507 0 0 1-.366-.626l1.133-4.178a.52.52 0 0 1 .634-.361.51.51 0 0 1 .366.626l-1 3.684 3.73.987a.51.51 0 0 1-.132 1.005ZM18.48 16.451a.515.515 0 0 1-.258-.068L.98 6.553a.509.509 0 0 1-.19-.699.521.521 0 0 1 .708-.187l17.241 9.83a.509.509 0 0 1 .19.698.519.519 0 0 1-.449.256Z"
      fill="#EA2A5C"
      stroke="#EA2A5C"
      strokeWidth=".295"
    />
    <path
      d="M15.1 18.056a.516.516 0 0 1-.498-.379L13.468 13.5a.51.51 0 0 1 .366-.626l4.23-1.12a.52.52 0 0 1 .634.363.51.51 0 0 1-.366.626l-3.73.986 1 3.685a.51.51 0 0 1-.501.644v-.001ZM1.521 10.313a.51.51 0 0 1-.134-1.005l3.732-.986-1-3.684a.51.51 0 0 1 .366-.626.519.519 0 0 1 .634.361l1.134 4.178c.035.13.017.27-.052.388a.516.516 0 0 1-.315.238l-4.23 1.12a.533.533 0 0 1-.135.017Z"
      fill="#EA2A5C"
      stroke="#EA2A5C"
      strokeWidth=".295"
    />
  </svg>
);
