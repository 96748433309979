import React, { FC } from 'react';
import { isAndroid } from 'react-device-detect';

interface Props {
  isLandscape?: boolean;
  isMobile?: boolean;
}

const OrientationStub: FC<Props> = ({ isLandscape, isMobile }) => {
  if (isMobile && isLandscape && !isAndroid) {
    return (
      <div className="centered stub">
        <div className="fdc aic stub__container">
          <img src="./img/stub.png" alt="stub" className="stub__img" />
          <h2 className="h2 stub__heading">
            Please rotate your device to portrait orientation
          </h2>
          <p className="p2 stub__text">
            If screen does not rotate, make sure your screen orientation is
            unlocked
          </p>
        </div>
      </div>
    );
  }

  return null;
};

export default OrientationStub;
