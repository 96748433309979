import React, {
  ChangeEvent,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import cn from 'classnames';

export interface Props {
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  wrapClassName?: string;
  inputClassName?: string;
  value?: string;
  label?: string;
  error?: boolean;
  errorText?: string;
  type?: 'number' | 'tel' | 'text';
  suffix?: string;
  prefix?: string;
  id?: string;
  maxChars?: number;
  invalidChars?: string[];
  focusedId?: string;

  onChange?: (value: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
}

const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      wrapClassName,
      inputClassName,
      value,
      disabled = false,
      label,
      error = false,
      errorText = 'Field is required',
      onChange,
      onBlur,
      onFocus,
      type = 'text',
      required,
      placeholder,
      suffix,
      prefix,
      id,
      maxChars,
      invalidChars,
      focusedId,
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (focusedId && id && focusedId === id) {
        inputRef.current?.focus();
      }
    }, [focusedId, id, inputRef]);

    useEffect(() => {
      document.addEventListener('wheel', () => {
        if (
          document &&
          document.activeElement &&
          type === 'number' &&
          document.activeElement === inputRef.current
        ) {
          inputRef.current.blur();
        }
      });
    }, [inputRef, type]);

    const wrapClass = cn('input-wrap', wrapClassName);

    const inputClass = cn(
      'p2 input',
      {
        input_error: error,
        input_prefix: prefix,
      },
      inputClassName,
    );

    const labelClassName = cn('p2 text-semi-bold input__label', {
      input__label_required: required,
    });

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        const { value: inputValue } = event.target;

        if (maxChars && inputValue.length > maxChars) {
          return;
        }

        if (onChange) {
          if (type === 'number' && +inputValue < 1) {
            onChange('');
          } else {
            onChange(inputValue);
          }
        }
      },
      [maxChars, onChange, type],
    );

    const renderedLabel = label && (
      <span className={labelClassName}>{label}</span>
    );

    const renderedPrefix = prefix && (
      <span className="p2 aic input__prefix">{prefix}</span>
    );

    const renderedSuffix = suffix && (
      <span className="p2 aic input__suffix">{suffix}</span>
    );

    const renderedErrorText = error && errorText && (
      <span className="p3 input__error">{errorText}</span>
    );

    const handleKeyPress = useCallback(
      (e: any) => {
        if (!invalidChars) {
          return;
        }

        if (type === 'number') {
          const reg = /[0-9]|\./;

          if (!reg.test(e.key)) {
            e.preventDefault();
          }
        }

        if (type === 'tel') {
          const reg = /[A-Za-z]|\./;

          if (reg.test(e.key)) {
            e.preventDefault();
          }
        }

        if (invalidChars && invalidChars.indexOf(e.key) !== -1) {
          e.preventDefault();
        }
      },
      [invalidChars, type],
    );

    return (
      <label className={wrapClass}>
        {renderedLabel}
        <div className="aic fww input-wrap__inner">
          {renderedPrefix}
          <input
            key="input"
            value={value}
            className={inputClass}
            disabled={disabled}
            onChange={handleChange}
            placeholder={placeholder}
            onBlur={onBlur}
            onFocus={onFocus}
            ref={ref || inputRef}
            type={type}
            id={id}
            onKeyPress={handleKeyPress}
            autoComplete="off"
          />
          {renderedSuffix}
          {renderedErrorText}
        </div>
      </label>
    );
  },
);

export default Input;
