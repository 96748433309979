/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useMemo } from 'react';
import cn from 'classnames';
import { Syringe } from 'views/icons/process';
import ProgressBar from '../ProgressBar';
import ProcessChart from '../ProcessChart';

interface Props {
  icon?: JSX.Element;
  title?: string;
  value: number;
  prevValue?: number;
  nextValue?: number;
  subtitle?: string;
  text?: string;
  className?: string;
  ref?: any;
  custom?: boolean;
}

const ProcessCard: FC<Props> = ({
  icon = <Syringe />,
  title = 'Retrieved eggs',
  subtitle,
  value = 10,
  prevValue,
  nextValue,
  text,
  className,
  custom,
  ref,
}) => {
  const cardClassName = cn('df process-card', className);

  const lambda = useMemo(
    () => (prevValue ? prevValue - value : null),
    [prevValue, value],
  );

  const renderedLambdaText = lambda && (
    <p className="process-card__text">
      <strong className="process-card__text_accent">{lambda}</strong>
      {text}
    </p>
  );

  return (
    <div className={cardClassName} ref={ref}>
      <div className="aic process-card__header">
        <span
          className={cn('process-card__icon', {
            'process-card__icon_custom': custom,
          })}
        >
          {icon}
        </span>
        <h3 className="process-card__title">{title}</h3>
      </div>
      <div className="fdc process-card__body">
        <div
          className={cn('df process-card__info', {
            'process-card__info_custom': !lambda,
          })}
        >
          <span className="process-card__value">
            <span className="process-card__value_accent">{value}</span>
            {subtitle}
          </span>
          {renderedLambdaText}
        </div>
        <ProgressBar progress={value} className="process-card__progress" />
        <ProcessChart className="process-card__chart" a={value} b={nextValue} />
      </div>
    </div>
  );
};

export default ProcessCard;
