import React, { FC } from 'react';

export const Web: FC = () => (
  <svg viewBox="0 0 24 24" stroke="#fff" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 19a7 7 0 1 0 0-14 7 7 0 0 0 0 14ZM5.467 9.667h13.066M5.467 14.333h13.066"
      fill="none"
      strokeWidth=".78"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.611 5a13.222 13.222 0 0 0 0 14M12.389 5a13.222 13.222 0 0 1 0 14"
      fill="none"
      strokeWidth=".78"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
