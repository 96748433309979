import React, {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';

import { ChevronDown } from 'views/icons';
import { RoundedClose } from 'views/icons/RoundedClose';
import MultiselectOption from './MultiselectOption';

const initialData: SelectOption[] = [
  { label: 'Option 1', value: 'option 1' },
  { label: 'Option 2', value: 'option 2' },
  { label: 'Option 3', value: 'option 3' },
  { label: 'Option 4', value: 'option 4' },
  { label: 'Option 5', value: 'option 5' },
];

interface SelectOption {
  label: string;
  value: string;
}

interface Props {
  disabled?: boolean;
  label?: string;
  className?: string;
  placeholder?: string;
  selectedValues: string[];
  options?: SelectOption[];
  htmlFor?: string;
  description?: string;
  required?: boolean;
  max?: number;
  error?: boolean;
  errorText?: string;
  focusedId?: string;

  onChange: (values: string[]) => void;
  onFocus?: (open: boolean, id?: string) => void;
}

const Multiselect: FC<Props> = ({
  disabled = false,
  label,
  className,
  placeholder,
  selectedValues,
  htmlFor,
  description,
  required,
  max,
  error = false,
  errorText,
  focusedId,
  onChange,
  onFocus,
  options = initialData || [],
}) => {
  const [isDropOpen, setDropOpen] = useState(false);
  const [currentFocus, setCurrentFocus] = useState(0);

  const ref = useRef<HTMLButtonElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);

  const wrapClassName = cn('multiselect-wrap', className);

  const labelClassName = cn('p2 text-semi-bold multiselect__label', {
    multiselect__label_required: required,
  });

  const multiselectClassName = cn('multiselect aic', {
    multiselect_open: isDropOpen && !disabled,
    multiselect_disabled: disabled,
    multiselect_error: error,
  });

  const titleClassName = cn('p2 multiselect__title', {
    multiselect__title_placeholder: selectedValues.length === 0,
  });

  const renderedLabel = label && (
    <label htmlFor={htmlFor} className={labelClassName} ref={labelRef}>
      {label}
    </label>
  );

  const renderedDescription = description && (
    <p className="p3 multiselect__description">{description}</p>
  );

  const renderedErrorText = error && errorText && (
    <span className="p3 multiselect__error">{errorText}</span>
  );

  const deleteTag = useCallback(
    (e: MouseEvent<HTMLSpanElement, globalThis.MouseEvent>, value: string) => {
      e.stopPropagation();

      onChange(selectedValues.filter((item) => item !== value));
    },
    [onChange, selectedValues],
  );

  const chosenTitle = useMemo(
    () => (
      <div className="aifs fww multiselect__tags">
        {options
          .filter(({ value }) => selectedValues.includes(value))
          .map((item) => (
            <div className="aic multiselect__tag" key={item.value}>
              <span className="p2 multiselect__tag-text">{item.label}</span>
              <span
                className="centered multiselect__delete"
                onClick={(e) => deleteTag(e, item.value)}
              >
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m9.10059 9.06543 5.60101 5.60107M9.10059 14.667l5.60101-5.60107"
                    stroke="#4D565C"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
          ))}
      </div>
    ),
    [deleteTag, options, selectedValues],
  );

  const closeDrop = useCallback(
    (e: any) => {
      e.stopPropagation();

      const node = ref.current?.className;

      const eventClasses = e.target.className;

      if (
        (eventClasses &&
          typeof eventClasses.includes !== 'undefined' &&
          (eventClasses.includes(node) ||
            eventClasses.includes('multiselect__title') ||
            eventClasses.includes('multiselect__tag-text') ||
            eventClasses.includes('multiselect__tags'))) ||
        (eventClasses && typeof eventClasses.baseVal === 'string')
      ) {
        return;
      }

      onFocus?.(false);

      setDropOpen(false);

      setCurrentFocus(0);
    },
    [onFocus],
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === 'ArrowDown') {
        // Down arrow
        e.preventDefault();

        setCurrentFocus(
          currentFocus === options.length - 1 ? 0 : currentFocus + 1,
        );
      } else if (e.code === 'ArrowUp') {
        // Up arrow
        e.preventDefault();

        setCurrentFocus(
          currentFocus === 0 ? options.length - 1 : currentFocus - 1,
        );
      } else if (e.code === 'Escape') {
        // Up arrow
        e.preventDefault();

        setDropOpen(false);

        onFocus?.(false, htmlFor || '');
      }
    },
    [currentFocus, htmlFor, onFocus, options.length],
  );

  useEffect(() => {
    if (isDropOpen) {
      onFocus?.(true, htmlFor || '');
      document.addEventListener('keydown', handleKeyDown, false);

      return () => {
        document.removeEventListener('keydown', handleKeyDown, false);
      };
    }

    onFocus?.(false);
  }, [handleKeyDown, htmlFor, isDropOpen, onFocus]);

  useEffect(() => {
    if (focusedId && htmlFor && focusedId === htmlFor) {
      labelRef.current?.focus();
    }
  }, [focusedId, htmlFor]);

  useEffect(() => {
    if (isDropOpen) {
      document.body.addEventListener('click', closeDrop);
    } else {
      document.body.removeEventListener('click', closeDrop);
    }

    return () => document.body.removeEventListener('click', closeDrop, false);
  }, [closeDrop, isDropOpen]);

  const toggleDrop = useCallback(
    (e: any) => {
      const node = ref.current?.className;

      const eventClasses = e.target.className;

      if (disabled) return;

      if (
        (eventClasses &&
          typeof eventClasses.includes !== 'undefined' &&
          (eventClasses.includes(node) ||
            eventClasses.includes('multiselect__title') ||
            eventClasses.includes('multiselect__tag-text') ||
            eventClasses.includes('multiselect__tags'))) ||
        (eventClasses && typeof eventClasses.baseVal === 'string')
      ) {
        setDropOpen((value) => !value);
      }
    },
    [disabled],
  );

  const handleChange = useCallback(
    (value: string, index: number) =>
      (e: MouseEvent<HTMLElement> | KeyboardEvent) => {
        e.stopPropagation();

        setCurrentFocus(index);

        if (
          selectedValues.some((item) => value === item) ||
          (max && selectedValues.length === max)
        ) {
          onChange(selectedValues.filter((item) => item !== value));
        } else {
          onChange([...selectedValues, value]);
        }
      },
    [max, onChange, selectedValues],
  );

  const handleClear = useCallback(
    (e: MouseEvent<HTMLSpanElement, globalThis.MouseEvent>) => {
      e.stopPropagation();

      onChange([]);
    },
    [onChange],
  );

  const renderedClearBtn = selectedValues.length > 0 && (
    <span className="centered multiselect__clear" onClick={handleClear}>
      <RoundedClose />
    </span>
  );

  return (
    <div className={wrapClassName}>
      <div className="fdc multiselect__header">
        {renderedLabel}
        {renderedDescription}
      </div>
      <div className="multiselect__inner">
        <button
          className={multiselectClassName}
          onClick={toggleDrop}
          id={htmlFor}
          ref={ref}
        >
          {selectedValues.length > 0 ? (
            chosenTitle
          ) : (
            <span className={titleClassName}>{placeholder}</span>
          )}
          <div className="aic multiselect__tools">
            {renderedClearBtn}
            <span className="centered multiselect__arrow">
              <ChevronDown />
            </span>
          </div>
        </button>
        <div className="multiselect__content">
          {options.map((item: SelectOption, index) => (
            <MultiselectOption
              key={item.value}
              label={item.label}
              onClick={handleChange(item.value, index)}
              focused={index === currentFocus}
              active={selectedValues.some((option) => option === item.value)}
            />
            // <div
            //   key={item.value}
            //   className={cn('multiselect__option aic jcsb', {
            //     select__option_active: selectedValues.some(
            //       (option) => option === item.value,
            //     ),
            //   })}
            //   onClick={handleChange(item.value)}
            // >
            //   <p className="p2 multiselect__text">{item.label}</p>
            //   {selectedValues.some((option) => option === item.value) && (
            //     <span className="multiselect__check">
            //       <svg
            //         viewBox="0 0 24 24"
            //         fill="none"
            //         xmlns="http://www.w3.org/2000/svg"
            //       >
            //         <path
            //           d="m18.0411 8.45343-7.3713 8.86017L6 13.001"
            //           stroke="#4D565C"
            //           strokeWidth="2"
            //           strokeLinecap="round"
            //           strokeLinejoin="round"
            //         />
            //       </svg>
            //     </span>
            //   )}
            // </div>
          ))}
        </div>
      </div>
      {renderedErrorText}
    </div>
  );
};

export default Multiselect;
