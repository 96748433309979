import React, { FC, MouseEvent, useEffect, useRef } from 'react';
import cn from 'classnames';

interface Props {
  label?: string;
  active?: boolean;
  focused?: boolean;

  onClick: (e: MouseEvent<HTMLElement>) => void;
}

const MultiselectOption: FC<Props> = ({ label, active, onClick, focused }) => {
  const optionRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (focused && optionRef && optionRef.current) {
      // Move element into view when it is focused
      optionRef.current.focus();
    }
  }, [focused]);

  return (
    <button
      className={cn('multiselect__option aic jcsb', {
        select__option_active: active,
      })}
      tabIndex={focused ? 0 : -1}
      onClick={onClick}
      ref={optionRef}
    >
      <p className="p2 multiselect__text">{label}</p>
      {active && (
        <span className="multiselect__check">
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m18.0411 8.45343-7.3713 8.86017L6 13.001"
              stroke="#4D565C"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      )}
    </button>
  );
};

export default MultiselectOption;
