import React, { FC, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';

import Input from '../Input/Input';
import Switch from '../Switch';

interface Props {
  className?: string;
  label?: string;
  required?: boolean;
  value?: string;
  unit?: string;
  prefix?: string;
  units: { label: string; value: string }[];
  htmlFor?: string;
  error?: boolean;
  maxChars?: number;
  invalidChars?: string[];
  size?: 'md' | 'lg';

  onChange?: (value: string, unit: string) => void;
}

const InputWithSwitch: FC<Props> = ({
  className,
  label = 'Weight',
  required = true,
  value,
  unit,
  units,
  htmlFor,
  prefix,
  error,
  maxChars,
  size,
  invalidChars,
  onChange,
}) => {
  const [activeUnit, setActiveUnit] = useState(unit || units[0].value);
  const [actualValue, setActualValue] = useState(value || '');

  useEffect(() => {
    if (value) {
      setActualValue(value);
    } else {
      setActualValue('');
    }
  }, [value]);

  useEffect(() => {
    if (unit) {
      setActiveUnit(unit);
    } else {
      setActiveUnit(units[0].value);
    }
  }, [unit, units]);

  const inputWithSwitchClassName = cn('input-switch', className);

  const labelClassName = cn('p2 text-semi-bold input-switch__label', {
    'input-switch__label_required': required,
  });

  const renderedLabel = label && (
    <label htmlFor={htmlFor} className={labelClassName}>
      {label}
    </label>
  );

  const handleInputChange = useCallback(
    (inputValue: string) => {
      if (+inputValue > 0) {
        setActualValue(inputValue);
        onChange?.(inputValue, activeUnit);
      } else {
        setActualValue('');
        onChange?.('', activeUnit);
      }
    },
    [activeUnit, onChange],
  );

  const handleSwitchChange = useCallback(
    (switchValue: string) => {
      setActiveUnit(switchValue);
      onChange?.('', switchValue);
    },
    [onChange],
  );

  return (
    <div className={inputWithSwitchClassName}>
      <div className="input-switch__header">
        {renderedLabel}
        <Switch
          units={units}
          unit={activeUnit}
          onChange={handleSwitchChange}
          className="input-switch__toggler"
          size={size}
        />
      </div>
      <div className="aic input-switch__inner">
        <Input
          value={actualValue}
          onChange={handleInputChange}
          wrapClassName="input-switch__input"
          suffix={activeUnit}
          type="number"
          id={htmlFor}
          error={error}
          maxChars={maxChars}
          invalidChars={invalidChars}
          prefix={prefix}
        />
      </div>
    </div>
  );
};

export default InputWithSwitch;
