import React, { FC } from 'react';

export const LoadingIcon: FC = () => (
  <svg viewBox="0 0 347 213" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="170.97" cy="163.652" rx="32.5" ry="5.5" fill="#EFE4E4" />
    <path
      d="M166.685 196.46c-27.093 2.692-56.303-13.537-70.715-33.808-41.5182-58.402 42-146.0002 100.5-107.0001 34.987 27.9261 48.159 81.8981 14 122.5001-10.584 12.578-31.161 17.055-43.785 18.308Z"
      fill="#FFE3E4"
      fillOpacity=".49"
    />
    <circle
      cx="130.648"
      cy="55.3305"
      r="11.8223"
      transform="rotate(-13 130.648 55.3305)"
      fill="#F4BBCB"
    />
    <path
      d="m150.133 146.874-.085-.142c-1.061-1.789-2.787-5.263-2.967-9.879-.26-6.684 2.891-11.447 4.314-13.597l.005-.007.468.31-.468-.31c1.069-1.616 4.376-6.5 10.234-9.149.641-.29 1.171-.488 2.036-.813.122-.046.251-.094.388-.145 1.11-.418 2.769-1.05 5.672-2.228 3.81-1.547 4.281-1.811 4.882-2.245l-24.479 38.205Zm0 0 .148.074m-.148-.074.148.074m0 0 23.853 11.914.001.001.148.074m-24.002-11.989 24.002 11.989m0 0 .165-.018m-.165.018.165-.018m0 0c2.068-.227 5.882-.934 9.68-3.562m-9.68 3.562 9.68-3.562m0 0c5.501-3.808 7.415-9.188 8.279-11.616l.003-.009-.529-.188m-7.753 11.813 7.753-11.813m0 0c.644-1.811 2.512-7.259 1.148-13.367l.548-.122v-.001c-.154-.69-.313-1.234-.576-2.128-.036-.122-.073-.25-.113-.385-.333-1.139-.825-2.845-1.627-5.873m.62 21.876-.62-21.876m0 0c-1.054-3.975-1.124-4.51-1.138-5.252-.082-4.252 2.111-7.439 2.874-8.512m-1.736 13.764 1.736-13.764m0 0c.376-.53 1.225-1.243 2.431-2.064 1.185-.807 2.652-1.678 4.196-2.53 3.089-1.704 6.454-3.3134 8.423-4.1657.845-.3653 5.511-2.4298 9.594-6.4666v-.0001l.007-.0071c1.736-1.7166 7.75-7.6612 8.133-16.8159l.015-.3634m-32.799 32.4128 32.799-32.4128m0 0-.325-.1626m.325.1626-.325-.1626m0 0-39.243-19.6001-.325-.1626m39.568 19.7627-39.568-19.7627m0 0-.282.2305m.282-.2305-.282.2305m0 0c-7.089 5.8055-8.229 14.184-8.558 16.6035l-.002.01v.0001m8.56-16.6136-8.56 16.6136m0 0c-.774 5.6895.378 10.6598.593 11.5535v.0002m-.593-11.5537.593 11.5537m0 0c.501 2.0863 1.235 5.7435 1.728 9.2364.247 1.7467.432 3.4423.499 4.8748.067 1.4569.008 2.5651-.19 3.1841l.535.17m-2.572-17.4653 2.572 17.4653m0 0-.535-.17c-.399 1.253-1.63 4.922-5.078 7.41l5.613-7.24Z"
      fill="#FFFBFC"
      stroke="#FFFBFC"
      strokeWidth="1.12335"
    />
    <path
      d="M149.815 136.745c.108 2.762 1.742 8.387 3.126 10.904h-.002l18.791 9.385c2.845-.406 8.565-2.356 10.838-3.929 4.772-3.303 6.453-8.022 7.261-10.29l.001-.002c1.546-4.342 1.907-8.434 1.074-12.164-.131-.588-.269-1.06-.516-1.903l-.013-.042-.008-.03c-.34-1.157-.853-2.904-1.754-6.307-1.083-4.093-1.206-4.814-1.228-5.899-.067-3.485 1.07-6.899 3.379-10.15.626-.881 2.089-2.399 7.537-5.405 3.317-1.8293 6.737-3.4503 8.658-4.2814 1.162-.5031 5.23-2.4158 8.756-5.9017.837-.8271 2.578-2.5485 4.175-5.1138 1.951-3.135 3.013-6.4583 3.156-9.878l-35.692-17.8267c-2.648 2.1686-4.667 5.0139-6.001 8.4561-1.091 2.8177-1.422 5.2435-1.58 6.4098-.67 4.9115.245 9.3138.541 10.5451.489 2.0355 1.248 5.7419 1.778 9.4932.87 6.1631.535 8.2434.206 9.2734-1.212 3.798-3.259 6.758-6.085 8.798-.881.635-1.531.969-5.455 2.561-3.262 1.324-4.966 1.963-6.096 2.387l-.029.011c-.847.317-1.314.493-1.872.745-3.483 1.575-6.537 4.323-9.08 8.166l-.001.002c-1.328 2.009-4.09 6.187-3.865 11.986Z"
      fill="#FFF5F7"
    />
    <path
      d="M187.178 113.145c1.123.561 0-2.809 4.246-7.604.874-.987 2.766-2.359 6.877-4.627 3.317-1.8292 6.736-3.4494 8.657-4.2814.569-.2459 1.83-.8289 3.383-1.7669-6.014-2.8984-9.226-5.9077-11.051-8.5005-.426-.6043-1.44-2.1306-3.114-4.2779-2.082-2.6708-5.074-6.466-9.651-10.2648-1.467-1.2174-3.346-2.6423-5.655-4.0924-.714 2.2234-.967 4.0745-1.099 5.0477-.668 4.9121.246 9.3139.542 10.5452.489 2.0347 1.248 5.7419 1.777 9.4932.778 5.5081.593 7.7548.31 8.9048-.227 8.473 4.502 11.256 4.778 11.423v.001ZM183.721 130.351c1.822-.432 2.949-2.258 2.518-4.08-.432-1.822-2.258-2.95-4.08-2.518-1.822.431-2.949 2.258-2.518 4.08.431 1.822 2.258 2.949 4.08 2.518ZM183.677 140.987c-7.624-2.254-20.694-1.003-26.375-3.257-1.974-.783-4.538-1.804-7.372-3.738-.107.871-.152 1.79-.114 2.754.107 2.761 1.741 8.385 3.126 10.903l18.791 9.385c2.844-.405 8.565-2.357 10.837-3.929 4.268-2.954 6.063-7.04 6.973-9.493-1.67-1.022-3.62-1.962-5.865-2.626l-.001.001Z"
      fill="#FFC5CD"
    />
    <path
      opacity=".06"
      d="M198.301 100.914c3.317-1.8292 6.736-3.4494 8.657-4.2813 1.163-.5031 5.23-2.4158 8.756-5.9017.837-.8272 2.578-2.5489 4.175-5.1139 1.951-3.1341 3.013-6.4574 3.157-9.8779.128-3.0742-.428-6.0862-1.745-9.035l-2.231-.8923c-.065 3.2957-.295 4.7942-1.969 9.0322-4.054 10.2618-12.084 15.2534-15.708 17.4332-6.584 3.9612-13.234 5.4144-18.774 5.7726.099 2.2971-.112 3.3831-.322 4.0391-.967 3.032-2.467 5.528-4.469 7.45.831.979 1.646 2.06 2.413 3.286 1.135 1.814 4.767 7.616 4.363 15.402 0 0-.436 8.43-6.529 15.407-3.871 4.435-9.65 7.255-16.463 8.347l10.119 5.054c2.844-.405 8.565-2.357 10.837-3.929 4.773-3.304 6.454-8.023 7.262-10.292 1.546-4.341 1.907-8.434 1.075-12.164-.134-.598-.274-1.076-.529-1.944-.34-1.157-.853-2.905-1.762-6.338-1.085-4.093-1.208-4.814-1.228-5.899-.067-3.484 1.069-6.899 3.378-10.15.626-.881 2.088-2.399 7.538-5.405h-.001Z"
      fill="#720101"
    />
    <path
      d="M155.822 131.114c-.696-.251-1.033-1.038-.737-1.715 1.222-2.788 3.963-7.427 9.37-10.031 1.963-.945 4.069-1.53 6.277-1.74.74-.071 1.387.493 1.407 1.236l.001.057c.018.667-.481 1.239-1.145 1.303-1.906.181-3.72.682-5.407 1.495-4.668 2.249-7.048 6.294-8.112 8.726-.269.615-.969.914-1.599.687l-.052-.018h-.003Z"
      fill="#fff"
      opacity=".41"
    />
    <path
      d="m230.502 70.67-43.79-21.8713c-1.907-.9528-4.226-.179-5.179 1.7283-.952 1.9073-.178 4.2258 1.73 5.1786l43.79 21.8713c1.907.9528 4.226.179 5.178-1.7283.953-1.9073.179-4.2258-1.729-5.1786Z"
      fill="#E991A8"
    />
    <path
      d="m230.504 70.6705-8.543-4.267c1.907.9527 2.682 3.2715 1.729 5.1791-.953 1.9075-3.272 2.6816-5.179 1.7288l8.543 4.267c1.907.9527 4.226.1787 5.179-1.7289.953-1.9075.179-4.2263-1.729-5.179Z"
      fill="#DD7D96"
    />
    <path
      d="m185.887 157.224-43.79-21.871c-1.908-.953-4.226-.179-5.179 1.728-.952 1.907-.178 4.226 1.729 5.178l43.79 21.872c1.908.953 4.227.179 5.179-1.729.953-1.907.179-4.225-1.729-5.178Z"
      fill="#E991A8"
    />
    <path
      d="m185.888 157.224-8.543-4.267c1.907.953 2.681 3.272 1.729 5.18-.953 1.907-3.272 2.681-5.18 1.728l8.544 4.267c1.907.953 4.226.179 5.179-1.729.952-1.907.178-4.226-1.729-5.179Z"
      fill="#DD7D96"
    />
    <path
      d="m222.47 110.873 22.852-7.712M223.109 121.092l10.757 4.935"
      stroke="#DE7792"
      strokeWidth="3.04044"
      strokeLinecap="round"
    />
  </svg>
);
