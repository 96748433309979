/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import ReactGA from 'react-ga';

import { validateEmail } from 'utils/validators';

import { ErrorIcon } from 'views/icons';

import {
  Drops,
  Fertilized,
  Frozen,
  Implanted,
  Nipple,
  Syringe,
  Transfer,
} from 'views/icons/process';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'utils/constants/routes';

type ResultType = 'success' | 'error';

const usePopupsContainer = (element?: HTMLDivElement | null) => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [request, setRequest] = useState<boolean>(false);
  const [agreement, setAgreement] = useState<boolean>(false);
  const [emailErrorText, setEmailErrorText] = useState<string>('');
  const [amhModalIsOpen, setAmhModalIsOpen] = useState<boolean>(false);
  const [processModalIsOpen, setProcessModalIsOpen] = useState<boolean>(false);
  const [loadingModalIsOpen, setLoadingModalIsOpen] = useState<boolean>(false);
  const [resultModalType, setResultModalType] = useState<ResultType | null>(
    null,
  );
  const [validFieldErrors, setValidFieldErrors] = useState<string[]>([]);
  const [requestIsCanceled, setRequestIsCanceled] = useState<boolean>(false);
  const [ip, setIP] = useState('');

  const controller = useMemo(() => new AbortController(), [requestIsCanceled]);

  const location = useLocation();

  const actualPageName = useMemo(() => {
    if (location.pathname === ROUTES.home) {
      return 'home page';
    }

    if (location.pathname === ROUTES.results) {
      return 'results page';
    }

    if (location.pathname === ROUTES.next) {
      return 'next steps page';
    }

    return '';
  }, [location.pathname]);

  const getData = useCallback(async () => {
    const res = await axios.get('https://geolocation-db.com/json/');

    setIP(res.data.IPv4);
  }, []);

  const eventTracker = useCallback(
    (category: string, action: string, label?: string) =>
      ReactGA.event({
        category: `${category} on the ${actualPageName}`,
        action,
        label,
      }),
    [actualPageName],
  );

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (validFieldErrors.length > 0) {
      if (
        element &&
        validFieldErrors.length === 1 &&
        validFieldErrors.includes('agreement')
      ) {
        element.scrollIntoView({ block: 'end', behavior: 'smooth' });
      } else if (element) {
        element.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    }

    if (requestIsCanceled) {
      timeout = setTimeout(() => {
        setRequestIsCanceled(false);
        clearTimeout(timeout);
      }, 1000);
    }

    return () => clearTimeout(timeout);
  }, [element, validFieldErrors.length, requestIsCanceled]);

  const handleNameChange = useCallback((value: string) => {
    setValidFieldErrors((prev) =>
      prev.filter((error: string) => error !== 'name'),
    );

    setName(value);
  }, []);

  const handleNameFocus = useCallback(
    () =>
      setValidFieldErrors((prev) =>
        prev.filter((error: string) => error !== 'name'),
      ),

    [],
  );

  const handleEmailChange = useCallback((value: string) => {
    setValidFieldErrors((prev) =>
      prev.filter((error: string) => error !== 'email'),
    );

    setEmail(value);
  }, []);

  const handleEmailFocus = useCallback(
    () =>
      setValidFieldErrors((prev) =>
        prev.filter((error: string) => error !== 'email'),
      ),

    [],
  );

  const handlePhoneChange = useCallback((value: string) => {
    setValidFieldErrors((prev) =>
      prev.filter((error: string) => error !== 'phone'),
    );

    setPhone(value);
  }, []);

  const handlePhoneFocus = useCallback(
    () =>
      setValidFieldErrors((prev) =>
        prev.filter((error: string) => error !== 'phone'),
      ),

    [],
  );

  const handleAgreementChange = useCallback((checked: boolean) => {
    setValidFieldErrors((prev) =>
      prev.filter((error: string) => error !== 'agreement'),
    );

    setAgreement(checked);
  }, []);

  const handleFormClear = useCallback(() => {
    setName('');
    setEmail('');
    setAgreement(false);
    setPhone('');
  }, []);

  const openProcessModal = useCallback(() => {
    setProcessModalIsOpen(true);

    document.body.classList.toggle('overflowed', true);
  }, []);

  const closeProcessModal = useCallback(() => {
    setProcessModalIsOpen(false);

    document.body.classList.toggle('overflowed', false);
  }, []);

  const openAmhModal = useCallback(() => {
    setAmhModalIsOpen(true);

    handleFormClear();

    document.body.classList.toggle('overflowed', true);
  }, [handleFormClear]);

  const closeAmhModal = useCallback(() => {
    setAmhModalIsOpen(false);

    setValidFieldErrors([]);

    document.body.classList.toggle('overflowed', false);
  }, []);

  const openResultModal = useCallback((type: ResultType) => {
    setResultModalType(type);

    document.body.classList.toggle('overflowed', true);
  }, []);

  const closeResultModal = useCallback(() => {
    setResultModalType(null);

    eventTracker('Thanks popup', `IP: ${ip}: Close thanks popup`, 'Close');

    document.body.classList.toggle('overflowed', false);
  }, [ip]);

  const toggleLoadingModal = useCallback((loading: boolean) => {
    if (loading) {
      setLoadingModalIsOpen(true);

      document.body.classList.toggle('overflowed', true);
    } else {
      setLoadingModalIsOpen(false);

      document.body.classList.toggle('overflowed', false);
    }
  }, []);

  const closeLoadingModal = useCallback(() => {
    controller.abort();

    setLoadingModalIsOpen(false);

    setRequestIsCanceled(true);

    document.body.classList.toggle('overflowed', false);
  }, [controller]);

  const checkFieldsValidation = useCallback(
    (values: { [key: string]: string }) =>
      Object.entries(values).forEach(([key, value]) => {
        if (!agreement) {
          setValidFieldErrors((prevErrors) =>
            prevErrors.includes('agreement')
              ? prevErrors
              : [...prevErrors, 'agreement'],
          );
        }

        if (!value) {
          setValidFieldErrors((prevErrors) =>
            prevErrors.includes(key) ? prevErrors : [...prevErrors, key],
          );

          setEmailErrorText('Please enter your email address');
        } else if (key === 'email' && !validateEmail(value)) {
          setValidFieldErrors((prevErrors) =>
            prevErrors.includes(key) ? prevErrors : [...prevErrors, key],
          );

          setEmailErrorText('Please enter a valid email address');
        }
      }),
    [agreement],
  );

  const checkFieldValidation = useCallback(
    (value: string, key: string) => () => {
      if (!value) {
        setValidFieldErrors((prevErrors) =>
          prevErrors.includes(key) ? prevErrors : [...prevErrors, key],
        );

        setEmailErrorText('Please enter your email address');
      } else if (key === 'email' && !validateEmail(value)) {
        setValidFieldErrors((prevErrors) =>
          prevErrors.includes(key) ? prevErrors : [...prevErrors, key],
        );

        setEmailErrorText('Please enter a valid email address');
      }
    },
    [],
  );

  const formData = useMemo(() => {
    const values = {
      name,
      email,
    };

    return {
      values,
      isValid:
        Object.values(values).every((el) => el) &&
        validateEmail(email) &&
        agreement,
    };
  }, [name, email, agreement]);

  const subscriptionFormData = useMemo(() => {
    const values = {
      name,
      email,
      phone,
    };

    return {
      values,
      isValid:
        Object.values(values).every((el) => el) &&
        validateEmail(email) &&
        agreement,
    };
  }, [name, phone, email, agreement]);

  const handleSubmit = useCallback(async () => {
    if (formData.isValid) {
      try {
        setRequest(true);

        const res = await axios.post(
          'https://l1fn6ipbf0.execute-api.us-east-1.amazonaws.com/contact',
          { name, email, ip },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );

        setAmhModalIsOpen(false);

        handleFormClear();

        setRequest(false);

        if (res.status === 200) {
          openResultModal('success');
          eventTracker(
            'Thanks popup',
            `IP: ${ip}: Open success popup`,
            'Success',
          );
        } else {
          openResultModal('error');
          eventTracker('Thanks popup', `IP: ${ip}: Open error popup`, 'Error');
        }
      } catch (e) {
        openResultModal?.('error');
        setRequest(false);
      }
    } else {
      checkFieldsValidation(formData.values);
    }

    eventTracker(
      'Subscribe form',
      `IP: ${ip}: Click on the submit button`,
      'Submit',
    );
  }, [
    checkFieldsValidation,
    email,
    formData.isValid,
    formData.values,
    handleFormClear,
    name,
    openResultModal,
    ip,
  ]);

  const handleVoucherSubmit = useCallback(async () => {
    if (subscriptionFormData.isValid) {
      try {
        setRequest(true);

        const res = await axios.post(
          'https://l1fn6ipbf0.execute-api.us-east-1.amazonaws.com/contact',
          { name, email, phone, ip },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );

        setAmhModalIsOpen(false);

        handleFormClear();

        setRequest(false);

        if (res.status === 200) {
          openResultModal('success');
          eventTracker(
            'Thanks popup',
            `IP: ${ip}: Open success popup`,
            'Success',
          );
        } else {
          openResultModal('error');
          eventTracker('Thanks popup', `IP: ${ip}: Open error popup`, 'Error');
        }
      } catch (e) {
        openResultModal?.('error');
        setRequest(false);
      }
    } else {
      checkFieldsValidation(subscriptionFormData.values);
    }

    eventTracker(
      'Subscription form',
      `IP: ${ip}: Click on the submit button`,
      'Submit',
    );
  }, [
    checkFieldsValidation,
    email,
    subscriptionFormData.isValid,
    subscriptionFormData.values,
    handleFormClear,
    name,
    phone,
    openResultModal,
    ip,
  ]);

  // Mocked cards

  const cards: ProcessCardProps[] = useMemo(
    () => [
      {
        title: 'Retrieved eggs',
        value: 15,
        icon: <Syringe />,
        subtitle: 'eggs',
      },
      {
        title: 'Frozen eggs',
        subtitle: 'eggs',
        value: 12,
        icon: <Frozen />,
        text: 'Non mature eggs',
        custom: true,
      },
      {
        title: 'Thawed',
        subtitle: 'eggs',
        value: 10,
        icon: <Drops />,
        text: 'Not survived thawing',
      },
      {
        title: 'Fertilized',
        subtitle: 'embryos ',
        value: 7,
        icon: <Fertilized />,
        text: 'Failed fertilization',
      },
      {
        title: 'Good for transfer',
        subtitle: 'embryos',
        value: 5,
        icon: <Transfer />,
        text: 'Not good for transfer',
      },
      {
        title: 'Implanted',
        subtitle: 'embryos',
        value: 2,
        icon: <Implanted />,
        text: 'Failed implantations',
      },
      {
        title: 'Live birth',
        subtitle: 'baby',
        value: 1,
        icon: <Nipple />,
        text: 'Pregnancy loss',
      },
    ],
    [],
  );

  const amhModalProps = useMemo(
    () => ({
      active: amhModalIsOpen,
      fields: [
        {
          name: 'name',
          label: 'Your name',
          value: name,
          onChange: handleNameChange,
          onFocus: handleNameFocus,
          onBlur: checkFieldValidation(name, 'name'),
          error: validFieldErrors.includes('name'),
          disabled: request,
          errorText: 'Please enter your name',
        },
        {
          name: 'email',
          label: 'Your email',
          value: email,
          onChange: handleEmailChange,
          onFocus: handleEmailFocus,
          onBlur: checkFieldValidation(email, 'email'),
          error: validFieldErrors.includes('email'),
          errorText: emailErrorText,
          disabled: request,
        },
      ],
      loading: request,
      checked: agreement,
      checkError: validFieldErrors.includes('agreement'),
      onCheck: handleAgreementChange,
      onClose: closeAmhModal,
      onSubmit: handleSubmit,
    }),
    [
      amhModalIsOpen,
      closeAmhModal,
      email,
      request,
      emailErrorText,
      agreement,
      handleEmailChange,
      handleNameFocus,
      handleEmailFocus,
      handleNameChange,
      handleSubmit,
      handleAgreementChange,
      name,
      validFieldErrors,
    ],
  );

  const subscriptionFormProps: FormProps = useMemo(
    () => ({
      fields: [
        {
          name: 'voucher-name',
          label: 'Your name',
          value: name,
          onChange: handleNameChange,
          onFocus: handleNameFocus,
          onBlur: checkFieldValidation(name, 'name'),
          error: validFieldErrors.includes('name'),
          disabled: request,
          errorText: 'Please enter your name',
          withButton: true,
          type: 'text',
        },
        {
          name: 'voucher-phone',
          label: 'Your phone number',
          value: phone,
          onChange: handlePhoneChange,
          onFocus: handlePhoneFocus,
          onBlur: checkFieldValidation(phone, 'phone'),
          error: validFieldErrors.includes('phone'),
          errorText: 'Please enter your phone number',
          disabled: request,
          type: 'tel',
          invalidChars: ['=', ',', 'e', 'E'],
        },
        {
          name: 'voucher-email',
          label: 'Your email',
          value: email,
          onChange: handleEmailChange,
          onFocus: handleEmailFocus,
          onBlur: checkFieldValidation(email, 'email'),
          error: validFieldErrors.includes('email'),
          errorText: emailErrorText,
          disabled: request,
          type: 'text',
        },
      ],
      loading: request,
      checked: agreement,
      checkError: validFieldErrors.includes('agreement'),
      onCheck: handleAgreementChange,
      onSubmit: handleVoucherSubmit,
    }),
    [
      email,
      request,
      phone,
      emailErrorText,
      agreement,
      handleEmailChange,
      handleNameFocus,
      handleEmailFocus,
      handleNameChange,
      handlePhoneChange,
      handlePhoneFocus,
      handleSubmit,
      handleAgreementChange,
      name,
      validFieldErrors,
    ],
  );

  const processModalProps = useMemo(
    () => ({
      active: processModalIsOpen,
      cards,
      onClose: closeProcessModal,
    }),
    [cards, closeProcessModal, processModalIsOpen],
  );

  const resultModalProps = useMemo(
    () => ({
      success: {
        active: resultModalType === 'success',
        onClose: closeResultModal,
      },
      error: {
        active: resultModalType === 'error',
        icon: <ErrorIcon />,
        heading: 'Something went wrong!',
        text: 'Please try again in a few minutes or contact support for help',
        link: '/',
        btnText: 'Back',
        onClose: closeResultModal,
      },
    }),
    [closeResultModal, resultModalType],
  );

  const loadingModalProps = useMemo(
    () => ({
      active: loadingModalIsOpen,
      onClose: closeLoadingModal,
    }),
    [closeLoadingModal, loadingModalIsOpen],
  );

  return {
    amhModalProps,
    subscriptionFormProps,
    controller,
    processModalProps,
    loadingModalProps,
    toggleLoadingModal,
    openAmhModal,
    openProcessModal,
    openResultModal,
    resultModalProps: resultModalType && resultModalProps[resultModalType],
  };
};

export default usePopupsContainer;
