import React, { FC, useCallback, useEffect, useState } from 'react';
import { getTrackBackground, Range } from 'react-range';
import cn from 'classnames';

export interface Props {
  step?: number;
  value?: number;
  min?: number;
  max?: number;
  minValue?: number;
  className?: string;

  onChange?: (values: number) => void;
}

interface TrackProps {
  props: {
    style: React.CSSProperties;
    ref: React.RefObject<any>;

    onMouseDown: (e: React.MouseEvent) => void;
    onTouchStart: (e: React.TouchEvent) => void;
  };
  children: React.ReactNode;
}

const RangeSlider: FC<Props> = ({
  step = 1,
  value,
  minValue = 0,
  min = 0,
  max = 20,
  onChange,
  className,
}) => {
  const [sliderValue, setSliderValue] = useState([0]);

  const sliderClassName = cn('centered range-slider', className);

  useEffect(() => {
    if (value && value <= max) {
      setSliderValue([value]);
    } else if (value && value > max) {
      setSliderValue([max]);
    } else {
      setSliderValue([0]);
    }
  }, [value, setSliderValue, max]);

  const handleSliderChange = useCallback(
    (values: number[]) => {
      if (values[0] < minValue || values[0] > max) {
        return;
      }

      onChange?.(values[0]);

      setSliderValue(values);
    },
    [max, minValue, onChange],
  );

  return (
    <div className={sliderClassName}>
      <Range
        values={sliderValue}
        step={step}
        min={min}
        max={max}
        onChange={(values) => handleSliderChange(values)}
        renderTrack={({ props, children }: TrackProps) => (
          <div
            className="range-slider__slider"
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
          >
            <div
              className="range-slider__track"
              ref={props.ref}
              style={{
                background: getTrackBackground({
                  values: sliderValue,
                  colors: ['#EA2A5C', 'RGBA(172,184,192,0.12)'],
                  min,
                  max,
                }),
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }: any) => (
          <div className=" range-slider__thumb" {...props} />
        )}
      />
    </div>
  );
};

export default RangeSlider;
