import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import { useLocation } from 'react-router-dom';

const useAnalyticsContainer = () => {
  const location = useLocation();

  // const analyticCreds = useMemo(
  //   () => ({
  //     gaTrackingCode: process.env.REACT_APP_GA_TRACKING_CODE || '',
  //     hjid: (process.env.REACT_APP_HOTJAR_ID || 0) as number,
  //     hjsv: (process.env.REACT_APP_HOTJAR_SNIPPED_VERSION || 0) as number,
  //   }),
  //   [],
  // );

  useEffect(() => {
    ReactGA.initialize('UA-237995350-2');
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    hotjar.initialize(3114683, 6);
  }, []);
};

export default useAnalyticsContainer;
