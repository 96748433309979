import React, { FC } from 'react';

export const Info: FC = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 3a9 9 0 1 1 0 18 9 9 0 0 1 0-18Z"
      stroke="#4D565C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity=".99"
      d="M12.156 7.225c.44 0 .796.358.796.8 0 .442-.357.8-.796.8a.798.798 0 0 1-.796-.8c0-.442.356-.8.796-.8Z"
      fill="#4D565C"
      stroke="#4D565C"
      strokeWidth=".5"
    />
    <path
      opacity=".99"
      d="M12.148 11v4.982"
      stroke="#4D565C"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
