import React, { FC } from 'react';
import cn from 'classnames';

import { Close } from 'views/icons';

interface Props {
  className?: string;
  age?: string;
  years?: string;
  active?: boolean;
  sticky?: boolean;

  onClose?: () => void;
}

const Marker: FC<Props> = ({
  className,
  age = '42',
  years = '7',
  active = true,
  sticky,
  onClose,
}) => {
  const markerClassName = cn(
    'marker',
    { marker_active: active, marker_sticky: sticky },
    className,
  );

  return (
    <div className={markerClassName}>
      <span className="marker__bg">
        <svg
          viewBox="0 0 140 86"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 75c-.05 5.817 6.08 10.564 13.692 10.597V43.923H0V75Z"
            fill="#7D4E82"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.974 64S0 63.826 0 76V20C0 8.953 8.96 0 20.005 0H108c17.673 0 32 14.327 32 32 0 17.673-14.327 32-32 32H13.975Z"
            fill="#A97AAD"
          />
        </svg>
      </span>
      <div className="aic jcsb marker__inner">
        <div className="marker__content">
          <h3 className="p3 text-semi-bold marker__title">
            {years === '1' ? `In ${years} year` : `In ${years} years`}
          </h3>
          <p className="p4 marker__text">Age {age}</p>
        </div>
        <button className="centered marker__close" onClick={onClose}>
          <Close />
        </button>
      </div>
    </div>
  );
};

export default Marker;
