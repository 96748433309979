/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import axios from 'axios';

import { originsList } from 'utils/constants/calculator-form';
import { ROUTES } from 'utils/constants/routes';

type ResultType = 'success' | 'error';

type AmhType = {
  value: string;
  unit: string;
};

const useCalculatorFormContainer = (
  element?: HTMLDivElement | null,
  setLoading?: (loading: boolean) => void,
  openResultModal?: (type: ResultType) => void,
  controller?: any,
  modalIsOpen?: boolean,
) => {
  const { state } = useLocation() as {
    state?: { userData?: UserData };
  };

  const [age, setAge] = useState<string>(state?.userData?.age || '');
  const [schema, setSchema] = useState<string>(
    state?.userData?.schema || 'standard',
  );
  const [weight, setWeight] = useState<string>(state?.userData?.weight || '');
  const [footsHeight, setFootsHeight] = useState<string>(
    state?.userData?.footsHeight || '',
  );
  const [inchesHeight, setInchesHeight] = useState<string>(
    state?.userData?.inchesHeight || '',
  );
  const [height, setHeight] = useState<string>(state?.userData?.height || '');
  const [origins, setOrigins] = useState<string[]>(
    state?.userData?.origins || [],
  );
  const [activeTab, setActiveTab] = useState<string>(
    state?.userData?.activeTab || '',
  );
  const [amh, setAmh] = useState<AmhType>(
    state?.userData?.amh || { value: '', unit: 'ng/mL' },
  );
  const [diagnoses, setDiagnoses] = useState<string[]>(
    state?.userData?.diagnoses || [],
  );
  const [validFieldErrors, setValidFieldErrors] = useState<string[]>([]);
  const [errorText, setErrorText] = useState<string>('');
  const [focusedId, setFocusedId] = useState<string>('');
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);
  const [isOpenDrop, setIsOpenDrop] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();

  const fields = useMemo(
    () =>
      schema === 'standard'
        ? ['age', 'foot-height', 'inch-height', 'weight', 'ethnicity']
        : ['age', 'height', 'weight', 'ethnicity'],
    [schema],
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === 'ArrowDown' || e.code === 'Tab') {
        // Down arrow and Tab
        e.preventDefault();

        if (focusedIndex !== fields.length - 1) {
          setFocusedIndex((prev) => (prev !== null ? prev + 1 : 0));
        }
      } else if (e.code === 'ArrowUp') {
        // Up arrow
        e.preventDefault();

        setFocusedIndex((prev) => (prev !== null && prev > 0 ? prev - 1 : 0));
      }
    },

    [fields.length, focusedIndex],
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, []);

  useEffect(() => {
    if (focusedIndex !== null) {
      setFocusedId(fields[focusedIndex]);
    }
  }, [fields, focusedIndex]);

  useEffect(() => {
    if (!isOpenDrop && !modalIsOpen && typeof modalIsOpen === 'boolean') {
      document.addEventListener('keydown', handleKeyDown, false);

      return () => {
        document.removeEventListener('keydown', handleKeyDown, false);
      };
    }
  }, [handleKeyDown, isOpenDrop, modalIsOpen]);

  useEffect(() => {
    navigate(location.pathname, {});
  }, [location.pathname, navigate]);

  useEffect(() => {
    if (validFieldErrors.length > 0 && element) {
      element.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, [element, validFieldErrors.length]);

  const handleAgeChange = useCallback((value: string) => {
    setValidFieldErrors((prev) =>
      prev.filter((error: string) => error !== 'age'),
    );

    if (+value > 0) {
      setAge(value);
    } else {
      setAge('');
    }
  }, []);

  const handleAgeFocus = useCallback(
    (id: string) => () => {
      setValidFieldErrors((prev) =>
        prev.filter((error: string) => error !== 'age'),
      );

      setFocusedIndex(fields.indexOf(id));
    },
    [fields],
  );

  const handleSchemaChange = useCallback((value: string) => {
    setValidFieldErrors((prev) =>
      prev.filter((error: string) => error !== 'age'),
    );

    setSchema(value);
    setWeight('');
    setHeight('');
    setFootsHeight('');
    setInchesHeight('');
    setValidFieldErrors([]);
    setErrorText('');
    setFocusedId('');
    setFocusedIndex(null);
  }, []);

  const handleWeightChange = useCallback((value: string) => {
    setValidFieldErrors((prev) =>
      prev.filter((error: string) => error !== 'weight'),
    );

    setWeight(value);
  }, []);

  const handleWightFocus = useCallback(
    (id: string) => () => {
      setValidFieldErrors((prev) =>
        prev.filter((error: string) => error !== 'weight'),
      );

      setFocusedIndex(fields.indexOf(id));
    },

    [fields],
  );

  const handleHeightChange = useCallback((value: string) => {
    setValidFieldErrors((prev) =>
      prev.filter((error: string) => error !== 'height'),
    );

    setHeight(value);
  }, []);

  const handleFootsHeightChange = useCallback((value: string) => {
    setValidFieldErrors((prev) =>
      prev.filter((error: string) => error !== 'height'),
    );

    setFootsHeight(value);
  }, []);

  const handleInchesHeightChange = useCallback((value: string) => {
    setValidFieldErrors((prev) =>
      prev.filter((error: string) => error !== 'height'),
    );

    setInchesHeight(value);
  }, []);

  const handleHeightFocus = useCallback(
    (id: string) => () => {
      setValidFieldErrors((prev) =>
        prev.filter((error: string) => error !== 'height'),
      );

      setFocusedIndex(fields.indexOf(id));
    },
    [fields],
  );

  const handleOriginsChange = useCallback(
    (values: string[]) => setOrigins(values),
    [],
  );

  const handleOriginsFocus = useCallback(
    (open: boolean, id?: string) => {
      setIsOpenDrop(open);
      if (id) {
        setFocusedIndex(fields.indexOf(id));
      }
    },
    [fields],
  );

  const handleTabChange = useCallback((value: string) => {
    setAmh({ value: '', unit: 'ng/mL' });
    setActiveTab(value);
  }, []);

  const handleAmhChange = useCallback(
    (value: string, unit: string) => setAmh({ value, unit }),
    [],
  );

  const handleDiagnosesChange = useCallback(
    (value: string) => {
      if (diagnoses.some((item) => value === item)) {
        setDiagnoses(diagnoses.filter((item) => item !== value));
      } else {
        setDiagnoses([...diagnoses, value]);
      }
    },
    [diagnoses],
  );

  const checkFieldsValidation = useCallback(
    (values: { [key: string]: any }) =>
      Object.entries(values).forEach(([key, value]) => {
        if (!value && typeof value === 'string') {
          setValidFieldErrors((prevErrors) =>
            prevErrors.includes(key) ? prevErrors : [...prevErrors, key],
          );
        } else if (
          key === 'age' &&
          !(+value > 19 && +value < 46) &&
          typeof value === 'string'
        ) {
          setValidFieldErrors((prevErrors) =>
            prevErrors.includes(key) ? prevErrors : [...prevErrors, key],
          );
          setErrorText('Sorry, we support ages 20-45');
        } else if (
          !Object.values(value).every((el) => el) &&
          typeof value === 'object'
        ) {
          setValidFieldErrors((prevErrors) =>
            prevErrors.includes(key) ? prevErrors : [...prevErrors, key],
          );
        }
      }),
    [],
  );

  const checkFieldValidation = useCallback(
    (value: string, key: string) => () => {
      if (!value && typeof value === 'string') {
        setValidFieldErrors((prevErrors) =>
          prevErrors.includes(key) ? prevErrors : [...prevErrors, key],
        );
      } else if (
        key === 'age' &&
        !(+value > 19 && +value < 46) &&
        typeof value === 'string'
      ) {
        setValidFieldErrors((prevErrors) =>
          prevErrors.includes(key) ? prevErrors : [...prevErrors, key],
        );
        setErrorText('Sorry, we support ages 20-45');
      }
    },
    [],
  );

  const actualWeight = useMemo(() => {
    if (schema === 'standard' && weight) {
      const recalculatedWeight = +weight * 0.453592;

      return `${recalculatedWeight}`;
    }

    return weight;
  }, [schema, weight]);

  const actualHeight = useMemo(() => {
    if (schema === 'standard' && (footsHeight || inchesHeight)) {
      const recalculatedHeight = +footsHeight * 30.48 + +inchesHeight * 2.54;

      return `${recalculatedHeight}`;
    }

    return height;
  }, [footsHeight, height, inchesHeight, schema]);

  const actualAmh = useMemo(() => {
    if (amh.unit === 'pmol/L') {
      const recalculatedAmh = +amh.value / 7.14;

      return `${recalculatedAmh}`;
    }

    return amh.value;
  }, [amh.unit, amh.value]);

  const formData = useMemo(() => {
    const values = {
      age,
      weight: actualWeight,
      height: actualHeight,
    };

    return {
      values,
      isValid:
        age &&
        +age > 19 &&
        +age < 46 &&
        Object.values(values).every((el) => el),
    };
  }, [age, actualWeight, actualHeight]);

  const originsData = useMemo(
    () => originsList.map((item) => ({ value: item, label: item })),
    [],
  );

  const tabsProps = useMemo(
    () => ({
      activeTab,
      tabs: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
      ],
      onChange: handleTabChange,
    }),
    [activeTab, handleTabChange],
  );

  const diagnosesCheckboxesData = useMemo(
    () => [
      {
        label: 'Polycystic ovary syndrome (PCOS)',
        tooltipText:
          'Polycystic ovary syndrome (PCOS) is a hormonal imbalance that may cause absent, irregular, or infrequent menstrual periods, as well as other symptoms including acne, excess body hair growth, scalp hair loss, or weight gain. If you have experienced these symptoms you might want to consult with a physician. ',
      },
      {
        label: 'Endometriosis',
        tooltipText:
          'Endometriosis is a condition that can cause pelvic pain and difficulty becoming pregnant. If you have experienced pelvic pain right before or during your menstrual period, between periods with more severe pain during menstruation, during or after sex, or during bowel movements or urination, you might want to consult with a physician. ',
      },
    ],
    [],
  );

  const handleSubmit = useCallback(async () => {
    if (formData.isValid) {
      const userData = {
        age,
        weight,
        height,
        inchesHeight,
        footsHeight,
        amh,
        origins,
        diagnoses,
        schema,
        activeTab,
      };

      setLoading?.(true);

      try {
        const res = await axios.post(
          'https://l1fn6ipbf0.execute-api.us-east-1.amazonaws.com',
          {
            age: +age,
            weight: +actualWeight,
            height: +actualHeight,
            ethnicity: origins,
            amh: actualAmh ? +actualAmh : null,
            condition: diagnoses,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
            signal: controller?.signal,
          },
        );

        const { results } = res.data;

        setLoading?.(false);
        setFocusedId('');
        setFocusedIndex(null);

        if (results && results.length > 0) {
          navigate(ROUTES.results, { state: { userData, results } });
        } else {
          openResultModal?.('error');
        }
      } catch (e) {
        setLoading?.(false);

        if (e.code !== 'ERR_CANCELED') {
          openResultModal?.('error');
        }
      }
    }
    checkFieldsValidation(formData.values);

    if (validFieldErrors.length > 0 && element) {
      element.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, [
    activeTab,
    actualAmh,
    actualHeight,
    actualWeight,
    age,
    amh,
    checkFieldsValidation,
    controller?.signal,
    diagnoses,
    element,
    footsHeight,
    formData.isValid,
    formData.values,
    height,
    inchesHeight,
    navigate,
    openResultModal,
    origins,
    schema,
    setLoading,
    validFieldErrors.length,
    weight,
  ]);

  return {
    age,
    weight,
    height,
    actualHeight,
    footsHeight,
    inchesHeight,
    origins,
    amh,
    diagnoses,
    validFieldErrors,
    diagnosesCheckboxesData,
    originsData,
    errorText,
    schema,
    tabsProps,
    focusedId,
    handleSchemaChange,
    handleAgeChange,
    handleAgeFocus,
    handleWeightChange,
    handleWightFocus,
    handleHeightChange,
    handleHeightFocus,
    handleFootsHeightChange,
    handleInchesHeightChange,
    handleOriginsChange,
    handleAmhChange,
    handleDiagnosesChange,
    handleSubmit,
    checkFieldValidation,
    handleOriginsFocus,
  };
};

export default useCalculatorFormContainer;
