import React, { FC } from 'react';

export const SuccessIcon: FC = () => (
  <svg viewBox="0 0 347 213" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M90.2749 56.4901C72.1233 78.7877 58.7708 116.068 67.7626 139.566c25.9073 67.695 155.5644 39.169 150.1684-30.5-3.54-45.6316-35.039-81.3275-89.894-73.1642-16.995 2.531-29.3043 10.1975-37.7621 20.5883Z"
      fill="#E6ECF0"
      fillOpacity=".6"
    />
    <path
      d="m201.5 99.5 19-21.5M208 107l13.5-4"
      stroke="#3C977C"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <circle
      cx="143.593"
      cy="77.2031"
      r="7.86147"
      transform="rotate(-13 143.593 77.2031)"
      fill="#BCD"
    />
    <g filter="url(#a-success)">
      <circle cx="173.686" cy="119.523" r="26.649" fill="#4CD5AC" />
    </g>
    <path
      opacity=".8"
      fillRule="evenodd"
      clipRule="evenodd"
      d="m168.157 126.933-.464-.455-4.238-4.176c-.469-.462-1.242-.476-1.726-.03-.486.446-.499 1.181-.029 1.643l2.742 2.701 2.809 2.766c.234.232.544.35.857.355.312.006.627-.101.869-.325l16.735-18.706c.486-.446.498-1.18.029-1.643-.47-.462-1.241-.476-1.726-.03l-15.858 17.9Z"
      fill="#fff"
      stroke="#fff"
      strokeWidth="3.98116"
    />
    <defs>
      <filter
        id="a-success"
        x="147.037"
        y="92.8738"
        width="53.2979"
        height="53.2981"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="7" dy="4" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix values="0 0 0 0 0.21401 0 0 0 0 0.766634 0 0 0 0 0.60125 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_182_18397" />
      </filter>
    </defs>
  </svg>
);
