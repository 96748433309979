import React, { FC } from 'react';

export const Transfer: FC = () => (
  <svg viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m15.77 2.846.615 1.231c.567 6.288-4.797 8.171-11.693 9.23l8.616.616.615 2.462L23.153 9 15.77 2.846Z"
      fill="#fff"
    />
    <path
      d="M7.154 4.692h6.615V1l9.693 8.154L13.769 17v-3.23H1m9.385-6.155H1.769m8.616 2.924H6.23"
      stroke="#EA2A5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
