import React, { FC } from 'react';

export const Nipple: FC = () => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m8.68 11.632 1.93-2.35a8.558 8.558 0 0 1 8.05-3.003l-.108-2.152-1.184-2.098L15.136.522 11.96 1.76l-1.479 2.77L9.029 7.84 7.523 10.07l1.157 1.56Z"
      fill="#fff"
    />
    <path
      d="m14.678 12.95.538-.027 1.183 2.098.081 1.614-1.506 2.233-2.152.107-3.255-.376-3.927-3.04-2.313-3.12-1.21-2.636c2.904 4.17 7.87 7.094 12.56 3.147Z"
      fill="#fff"
    />
    <path
      d="M17.65 2.15c-3.426-2.918-7.486-.465-7.513 4.962L7.64 10.044c.053.661.106 1.322 1.054 2.204"
      stroke="#EA2A5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.27 1.826c3.425 2.918 1.65 7.317-3.705 8.206l-2.498 2.93c-.67.054-1.34.108-2.383-.72a4.254 4.254 0 0 1-.052-.042M13.534 3.898c.324-.38 1.353-.817 2.114-.169"
      stroke="#EA2A5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.92 6.34c-2.436-1.01-4.69-.963-5.802.343-1.67 1.96-.132 6.012 3.435 9.05 3.567 3.04 7.812 3.914 9.482 1.954 1.112-1.306.8-3.54-.585-5.784"
      stroke="#000"
      strokeWidth=".932"
      strokeLinecap="round"
    />
    <path
      d="M7.92 6.34c-2.436-1.01-4.69-.963-5.802.343-1.67 1.96-.132 6.012 3.435 9.05 3.567 3.04 7.812 3.914 9.482 1.954 1.112-1.306.8-3.54-.585-5.784"
      stroke="#EA2A5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.674 13.038c-.642 1.503-.212 3.366 1.172 4.545 1.384 1.179 3.291 1.307 4.673.435"
      stroke="#EA2A5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
