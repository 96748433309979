import React, { FC, ReactNode, useEffect, useRef } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { ROUTES } from 'utils/constants/routes';

import { Logo } from 'views/icons';

import Footer from 'views/components/Footer';
import OrientationStub from 'views/features/OrientationStub';
import useWindowSize from 'hooks/useWindowSize';

interface Props {
  children?: ReactNode;
}

const Layout: FC<Props> = ({ children }) => {
  const {
    windowSize: { width },
    isHorizontal,
    isMobile,
  } = useWindowSize();

  const mainRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (typeof window !== 'undefined' && width > 1024) {
      mainRef?.current?.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [width]);

  return (
    <div className="layout">
      <img src="/img/banner-desk.svg" alt="banner" className="layout__img" />
      <div className="df container layout__container">
        <div className="fdc layout__hero">
          <Link to={ROUTES.home} className="layout__logo" reloadDocument>
            <div style={{ visibility: 'hidden' }}>
              <Logo />
            </div>
          </Link>
        </div>
        <div
          className={cn('fdc aic layout__main', {
            layout__main_overflowed:
              document.body.classList.contains('overflowed'),
          })}
          ref={mainRef}
        >
          <div className="layout__content">{children}</div>
          <Footer />
        </div>
      </div>
      <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="layout__oval layout__oval_top"
        viewBox="0 0 225 232"
      >
        <path
          opacity=".4"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M225 70c0 89.471-72.536 162-162.015 162C-26.494 232-99 159.471-99 70-99-19.47-26.494-92 62.985-92 152.464-92 225-19.47 225 70Z"
          fill="#FFE3E3"
        />
      </svg>
      <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="layout__oval layout__oval_middle"
        viewBox="0 0 122 201"
      >
        <path
          opacity=".5"
          d="M2.008 95.752c8.96 37.201 38.328 84.149 71.577 98.12C169.373 234.121 248.017 91.03 167.44 30.525 114.659-9.098 49.049-14.317 11.515 42.56-.112 60.182-2.168 78.418 2.008 95.752Z"
          fill="#FFE3E3"
        />
      </svg>
      <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="layout__oval layout__oval_bottom"
        viewBox="0 0 212 179"
      >
        <path
          opacity=".4"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M212 162c0 89.471-72.536 162-162.015 162C-39.494 324-112 251.471-112 162-112 72.53-39.494 0 49.985 0 139.464 0 212 72.53 212 162Z"
          fill="#FFE3E3"
        />
      </svg>
      <svg
        className="layout__ellipse"
        width="268"
        height="310"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M39.21 221.341c50.452 52.861 141.199 99.293 204.945 85.593 183.65-39.476 159.146-369.805-21.858-381.67C103.742-82.49.193-17.647.527 120.697c.108 42.862 15.172 76.014 38.684 100.645Z"
          fill="#FFE3E3"
        />
      </svg>
      <OrientationStub isLandscape={isHorizontal} isMobile={isMobile} />
    </div>
  );
};

export default Layout;
