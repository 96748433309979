import React, { FC } from 'react';

export const RoundedClose: FC = () => (
  <svg viewBox="0 0 24 25" fill="#D4DFE7" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12.5" r="8" />
    <path
      d="m9.19995 9.7002 5.60105 5.6011M9.19995 15.3008 14.801 9.69972"
      stroke="#fff"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m9.19995 9.7002 5.60105 5.6011M9.19995 15.3008 14.801 9.69972"
      stroke="#fff"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
