import { useCallback, useEffect, useMemo, useState } from 'react';

interface ReturnType {
  windowSize: { height: number; width: number };
  isVerticalScrolled: boolean;
  scrollTop: boolean;
  scrollIsVisible: boolean;
  isHorizontal: boolean;
  isMobile: boolean;
  breakpoint:
    | 'mobile'
    | 'tablet_vertical'
    | 'tablet_horizontal'
    | 'laptop'
    | 'desk';
}

const useWindowSize = (
  element?: HTMLDivElement | null,
  triggerOne?: boolean,
  triggerTwo?: boolean,
): ReturnType => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });
  const [isVerticalScrolled, setIsVerticalScrolled] = useState<boolean>(false);

  const [scrollIsVisible, setScrollIsVisible] = useState<boolean>(false);

  const [scrollTop, setScrollTop] = useState<boolean>(false);

  const [isHorizontal, setIsHorizontal] = useState<boolean>(false);

  const [isMobile, setIsMobile] = useState<boolean>(false);

  const handleResize = useCallback(() => {
    if (typeof window !== 'undefined') {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });

      if (window.matchMedia('(orientation: portrait)').matches) {
        setIsHorizontal(false);
      } else {
        setIsHorizontal(true);
      }

      if (
        navigator.userAgent.includes('Mobile') ||
        navigator.userAgent.includes('Tablet')
      ) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }

      if (element) {
        setIsVerticalScrolled(
          element.scrollHeight > element.getBoundingClientRect().height,
        );
        setScrollIsVisible(
          element.scrollHeight > element.getBoundingClientRect().height,
        );
      }
    }
  }, [element]);

  const onScroll = useCallback(() => {
    if (typeof window !== 'undefined') {
      if (element && element.scrollTop > 0 && !scrollTop) {
        setScrollTop(true);
      } else if (element && element.scrollTop === 0) {
        setScrollTop(false);
      }

      if (
        element &&
        element.scrollHeight - element.scrollTop === element.offsetHeight
      ) {
        setIsVerticalScrolled(false);
      } else {
        setIsVerticalScrolled(true);
      }
    }
  }, [element, scrollTop]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      element?.addEventListener('scroll', onScroll);

      onScroll();
    }

    return () => {
      if (typeof window !== 'undefined') {
        element?.removeEventListener('scroll', onScroll);
      }
    };
  }, [element, handleResize, onScroll]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);

      handleResize();
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, [handleResize, triggerOne, triggerTwo]);

  const breakpoint = useMemo(() => {
    switch (true) {
      case windowSize.width >= 768 && windowSize.width < 1024:
        return 'tablet_vertical';
      case windowSize.width >= 1024 && windowSize.width < 1110:
        return 'tablet_horizontal';
      case windowSize.width >= 1110 && windowSize.width < 1200:
        return 'laptop';
      case windowSize.width >= 1200:
        return 'desk';
      default:
        return 'mobile';
    }
  }, [windowSize.width]);

  return {
    windowSize,
    breakpoint,
    isVerticalScrolled,
    scrollTop,
    scrollIsVisible,
    isHorizontal,
    isMobile,
  };
};

export default useWindowSize;
