import React, { FC } from 'react';
import cn from 'classnames';

interface Props {
  fill?: string;
  className?: string;
}

const Spiner: FC<Props> = ({ fill = 'var(--white)', className }) => {
  const attr = {
    attributeName: 'r',
    from: '8',
    to: '0',
    dur: '2.2s',
    values: '0; 2; 4; 6; 8; 9; 8; 6; 4; 2; 0; 0; 0; 0; 0; 0',
    repeatCount: 'indefinite',
  };

  const spinerClassName = cn('spiner', className);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="19"
      viewBox="-3 -3 64 21"
      preserveAspectRatio="xMidYMid"
      className={spinerClassName}
    >
      <circle cx="7.5" cy="7.5" r="9" fill={fill}>
        <animate {...attr} begin="-0.32s" />
      </circle>
      <circle cx="29" cy="7.5" r="9" fill={fill}>
        <animate {...attr} begin="-0.16s" />
      </circle>
      <circle cx="50.5" cy="7.5" r="9" fill={fill}>
        <animate {...attr} />
      </circle>
    </svg>
  );
};

export default Spiner;
