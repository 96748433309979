import React, { FC, useRef } from 'react';

import usePopupsContainer from 'hooks/usePopupsContainer';

import Layout from 'views/hoc/Layout';

// import AMHModal from 'views/features/modals/AMHModal';
import ProcessModal from 'views/features/modals/ProcessModal';
import Results from 'views/features/Results';
import useResultsContainer from 'views/features/Results/hooks/useResultsContainer';

import ResultModal from 'views/features/modals/ResultModal';

const ResultsPage: FC = () => {
  const ref = useRef<HTMLHeadingElement>(null);
  const formRef = useRef<HTMLDivElement>(null);

  const { userData, tabsProps, markerProps, rounds, handleNext, handleBack } =
    useResultsContainer(ref.current);

  const {
    // amhModalProps,
    processModalProps,
    resultModalProps,
    subscriptionFormProps,
    // openAmhModal,
    openProcessModal,
  } = usePopupsContainer(formRef.current);

  return (
    <Layout>
      <Results
        userData={userData}
        tabsProps={tabsProps}
        markerProps={markerProps}
        // openAmhModal={openAmhModal}
        openProcessModal={openProcessModal}
        rounds={rounds}
        ref={ref}
        handleNext={handleNext}
        handleBack={handleBack}
        subscriptionFormProps={subscriptionFormProps}
      />
      {/* <AMHModal {...amhModalProps} ref={formRef} /> */}
      <ProcessModal {...processModalProps} />
      <ResultModal {...resultModalProps} />
    </Layout>
  );
};

export default ResultsPage;
