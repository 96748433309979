/* eslint-disable react/no-unescaped-entities */
import React, { FC } from 'react';
import cn from 'classnames';
import { nanoid } from 'nanoid';

import RoundCard from 'views/components/RoundCard';

const initialRounds: Round[] = [
  { id: nanoid(5), round: 1, eggs: 5, leastOne: '44%', leastTwo: '10%' },
  { id: nanoid(5), round: 2, eggs: 15, leastOne: '83%', leastTwo: '52%' },
  { id: nanoid(5), round: 3, eggs: 25, leastOne: '95%', leastTwo: '73%' },
  { id: nanoid(5), round: 4, eggs: 40, leastOne: '>99%', leastTwo: '94%' },
];

interface Props {
  rounds?: Round[];
  className?: string;
  withEmpty?: boolean;
  withExtra?: boolean;
}

const Rounds: FC<Props> = ({
  rounds = initialRounds,
  withEmpty,
  withExtra,
  className,
}) => {
  const roundsClassName = cn('fdc aic rounds', className);

  const renderedExtraField = rounds?.length === 1 && withExtra && (
    <div className="centered rounds__info">
      <p className="p3 text-center rounds__text rounds__text_extra">
        According to your results, <br />
        <strong className="text-semi-bold rounds__text_accent">
          no further rounds
        </strong>
        will be needed
      </p>
    </div>
  );

  const renderedEmptyField = withEmpty && (
    <div className="fdc rounds__info">
      <h3 className="p2 text-semi-bold rounds__heading">
        Results can be predicted up to the age of 45
      </h3>
      <p className="p3 rounds__text rounds__text_mb">
        After the age of 45, the chances of retrieving a quality egg decrease
        significantly. Therefore, we recommend you don’t wait and start your
        fertility preservation journey as soon as possible.
      </p>
      <p className="p3 rounds__text">
        With that being said, don't let it break your spirit. There are various
        ways to fulfill your dream of starting a family, on which you can
        consult with a fertility specialist.
      </p>
    </div>
  );

  const renderedContent = withEmpty ? (
    renderedEmptyField
  ) : (
    <>
      {rounds?.map((round) => (
        <RoundCard {...round} single={rounds?.length === 1} key={round.id} />
      ))}
      {renderedExtraField}
    </>
  );

  if (!rounds || rounds.length === 0) {
    return null;
  }

  return <div className={roundsClassName}>{renderedContent}</div>;
};

export default Rounds;
