import React, { FC } from 'react';

export const Check: FC = () => (
  <svg viewBox="0 0 10 9" stroke="#FF5F88" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.419 1.8 3.918 7.286l-2.7-2.336"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
