import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
// import cn from 'classnames';
// import { isDesktop } from 'react-device-detect';

import useWindowSize from 'hooks/useWindowSize';

import { ArrowRight, ChevronLeft, Lamp } from 'views/icons';

import Button from 'views/components/Button';
import Marker from 'views/components/Marker';
import Tabs from 'views/components/Tabs';
import TextButton from 'views/components/TextButton';
import UserInfo from 'views/components/User';
// import ToolsList from 'views/components/ToolsList';

import Rounds from '../Rounds';
import SubscriptionForm from '../SubscriptionForm';

interface Props {
  userData: UserData;
  tabsProps: TabsProps;
  markerProps: MarkerProps;
  rounds?: Round[];
  subscriptionFormProps: FormProps;

  openAmhModal?: () => void;
  openProcessModal: () => void;
  handleNext?: () => void;
  handleBack?: () => void;
}

const Results = forwardRef<HTMLHeadingElement, Props>(
  (
    {
      userData,
      tabsProps,
      markerProps,
      rounds,
      subscriptionFormProps,
      // openAmhModal,
      openProcessModal,
      handleNext,
      handleBack,
    },
    ref,
  ) => {
    const [shareMenuIsVisible, setShareMenuIsVisible] =
      useState<boolean>(false);

    const { windowSize } = useWindowSize();

    const { weight, height, amh } = userData;

    // const toggleShareMenu = useCallback((e: any) => {
    //   e.stopPropagation();

    //   setShareMenuIsVisible((prev) => !prev);
    // }, []);

    const closeShareMenu = useCallback(() => setShareMenuIsVisible(false), []);

    useEffect(() => {
      if (shareMenuIsVisible) {
        document.body.addEventListener('click', closeShareMenu);
      } else {
        document.body.removeEventListener('click', closeShareMenu);
      }

      return () =>
        document.body.removeEventListener('click', closeShareMenu, false);
    }, [closeShareMenu, shareMenuIsVisible]);

    const actualRounds = useMemo(() => {
      if (rounds && rounds.length > 1) {
        return rounds?.slice(0, rounds.length - 1);
      }

      return rounds;
    }, [rounds]);

    const withEmpty = useMemo(
      () => (markerProps.age ? +markerProps.age > 45 : false),
      [markerProps.age],
    );

    const renderedExtraRoundField = rounds &&
      rounds.length > 1 &&
      !withEmpty && (
        <div className="results__extra">
          <Rounds rounds={rounds?.slice(rounds.length - 1)} />
        </div>
      );

    const renderedStub = rounds && rounds.length > 1 && !withEmpty && (
      <div className="results__stub" />
    );

    // const renderedAmhLink = !userData.amh.value && (
    //   <>
    //     <p className="p3 results__text">
    //       Want to get more accurate results? Enter your AMH level
    //     </p>
    //     <TextButton className="results__btn" onClick={openAmhModal}>
    //       Learn more about the AMH test
    //       <span className="centered results__arrow">
    //         <ArrowRight />
    //       </span>
    //     </TextButton>
    //   </>
    // );

    // const renderedShareBtn = isDesktop ? (
    //   <ToolsList
    //     active={shareMenuIsVisible}
    //     position={windowSize.width > 1756 ? 'left' : 'top'}
    //   >
    //     <button
    //       className={cn(
    //         'p1 text-semi-bold centered btn btn_rounded btn_white results__share',
    //         { btn_active: shareMenuIsVisible },
    //       )}
    //       onClick={toggleShareMenu}
    //     >
    //       <span className="results__icon">
    //         <Share />
    //       </span>
    //     </button>
    //   </ToolsList>
    // ) : (
    //   <Button className="results__share" rounded white>
    //     <span className="results__icon">
    //       <Share />
    //     </span>
    //   </Button>
    // );

    const renderedNextBtnText =
      windowSize.width > 817
        ? 'Let’s talk about costs and more'
        : 'Cost and more';

    const renderedAmh = useMemo(
      () => (amh.value ? `${amh.value} ${amh.unit}` : ''),
      [amh],
    );

    return (
      <section className="results">
        <div className="results__main">
          <h3 className="h2 results__title" ref={ref}>
            Your estimated
            <strong className="results__marker">results</strong>
          </h3>
          <UserInfo
            {...userData}
            weight={weight}
            height={height}
            amh={renderedAmh}
            className="results__user"
          />
          <div className="results__rounds">
            <Marker {...markerProps} />
            <Rounds rounds={actualRounds} withEmpty={withEmpty} withExtra />
            {renderedStub}
          </div>
          {renderedExtraRoundField}
          <p className="p3 results__text">
            Wondering why it takes so many eggs to achieve one live birth?
          </p>
          <TextButton className="results__btn" onClick={openProcessModal}>
            Learn more about the process
            <span className="centered results__arrow">
              <ArrowRight />
            </span>
          </TextButton>
          {/* {renderedAmhLink} */}
          <h3 className="p2 text-semi-bold results__subtitle">
            Considering waiting? <br />
            Check how your results could change in the coming years
          </h3>
          <Tabs {...tabsProps} className="results__tabs" />
          <div className="aic fww results__footer">
            <div className="aic results__buttons">
              <Button
                rounded
                className="results__back"
                white
                disabled={subscriptionFormProps.loading}
                onClick={handleBack}
              >
                <span className="results__icon">
                  <ChevronLeft />
                </span>
              </Button>
              {/* {renderedShareBtn} */}
            </div>
            <Button className="results__next" onClick={handleNext}>
              {renderedNextBtnText}
            </Button>
          </div>
        </div>
        <div className="aic results__instruction results-instruction">
          <span className="centered results-instruction__lamp">
            <Lamp />
          </span>
          <div className="results-instruction__info">
            <h3 className="text-semi-bold results-instruction__title">
              Want to learn how the calculator was built?
            </h3>
            <a
              href="https://www.embryonics.me/_files/ugd/14e8f3_cfd0deb0de90432f98a7c194cd4db4eb.pdf"
              rel="noopener noreferrer"
              target="_blank"
              className="aic text-btn text-semi-bold results-instruction__link"
            >
              Read more about the science behind it
              <span className="centered results__arrow">
                <ArrowRight />
              </span>
            </a>
          </div>
        </div>
        <SubscriptionForm {...subscriptionFormProps} />
      </section>
    );
  },
);

export default Results;
