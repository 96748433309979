import { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { DateTime } from 'luxon';
import { nanoid } from 'nanoid';
import { useLocation, useNavigate } from 'react-router-dom';

import useWindowSize from 'hooks/useWindowSize';

import { ROUTES } from 'utils/constants/routes';

const useResultsContainer = (element?: HTMLHeadingElement | null) => {
  const [activeTab, setActiveTab] = useState<string>('');
  const [reportLink, setReportLink] = useState<string>('');

  const {
    state: { userData, results },
  } = useLocation() as {
    state: { userData: UserData; results: RoundResult[] };
  };

  const navigate = useNavigate();

  const {
    windowSize: { width },
  } = useWindowSize();

  const renderedWeight = useMemo(() => {
    if (userData.schema === 'standard') {
      return `${(+userData.weight).toFixed(1)} lb`;
    }

    return `${(+userData.weight).toFixed(1)} kg`;
  }, [userData.schema, userData.weight]);

  const renderedHeight = useMemo(() => {
    if (userData.schema === 'standard') {
      if (userData.footsHeight && !userData.inchesHeight) {
        return `${(+userData.footsHeight).toFixed(1)} ft`;
      }

      if (!userData.footsHeight && userData.inchesHeight) {
        return `${(+userData.inchesHeight).toFixed(1)} in`;
      }

      return `${(+userData.footsHeight).toFixed(
        1,
      )} ft, ${(+userData.inchesHeight).toFixed(1)} in`;
    }

    return `${(+userData.height).toFixed(1)} cm`;
  }, [
    userData.footsHeight,
    userData.height,
    userData.inchesHeight,
    userData.schema,
  ]);

  const handleGetReportLink = useCallback(
    async (extra: number) => {
      const body = {
        date: DateTime.now().toFormat('dd/MM/yyyy'),
        extra,
        age: +userData.age,
        weight: renderedWeight,
        height: renderedHeight,
        ethnicity:
          userData.origins && userData.origins.length > 0
            ? userData.origins?.join(', ')
            : '-',
        amh: userData.amh.value
          ? `${userData.amh.value} ${userData.amh.unit}`
          : '-',
        results,
      };

      try {
        const res = await axios.post(
          'https://l1fn6ipbf0.execute-api.us-east-1.amazonaws.com/pdf',
          body,
          {
            headers: {
              'Content-Type': 'application/json',
            },
            responseType: 'blob',
          },
        );

        const file = new Blob([res.data], { type: 'application/pdf' });

        const fileURL = URL.createObjectURL(file);

        if (fileURL) {
          setReportLink(fileURL);
        }
      } catch (e) {
        console.log(e);
      }
    },
    [
      renderedHeight,
      renderedWeight,
      results,
      userData.age,
      userData.amh.unit,
      userData.amh.value,
      userData.origins,
    ],
  );

  useEffect(() => {
    if (!reportLink) {
      handleGetReportLink(0);
    }
  }, [handleGetReportLink, reportLink]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, []);

  useEffect(() => {
    if (activeTab && element) {
      element.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  }, [activeTab, element]);

  const handleTabChange = useCallback(
    (value: string) => {
      setActiveTab(value);

      handleGetReportLink(+value);

      if (element) {
        element.scrollIntoView({ block: 'end', behavior: 'smooth' });
      }
    },
    [element, handleGetReportLink],
  );

  const handleTabClear = useCallback(() => {
    setActiveTab('');

    handleGetReportLink(0);

    if (element) {
      element.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  }, [element, handleGetReportLink]);

  const handleNext = useCallback(() => {
    if (userData && results) {
      navigate(ROUTES.next, { state: { userData, results } });
    } else {
      navigate(ROUTES.home);
    }
  }, [navigate, results, userData]);

  const handleBack = useCallback(() => {
    if (userData) {
      navigate(ROUTES.home, { state: { userData } });
    }
  }, [navigate, userData]);

  const renderedPercentage = useCallback((percentage: number) => {
    if (percentage < 1) {
      return '<1%';
    }

    if (percentage > 99) {
      return '>99%';
    }

    return `${percentage}%`;
  }, []);

  const mappedRounds = useMemo(
    () =>
      results.map(({ age, births, eggs }) => ({
        age,
        rounds: eggs.map((egg, index) => ({
          id: nanoid(5),
          round: index + 1,
          eggs: egg,
          leastOne: renderedPercentage(Math.round(births[index][0])),
          leastTwo: renderedPercentage(Math.round(births[index][1])),
        })),
      })),
    [renderedPercentage, results],
  );

  const rounds: Round[] = useMemo(() => {
    if (activeTab) {
      return (
        mappedRounds.find(({ age }) => age === +userData.age + +activeTab)
          ?.rounds || []
      );
    }

    return mappedRounds.find(({ age }) => age === +userData.age)?.rounds || [];
  }, [activeTab, mappedRounds, userData.age]);

  const tabsProps = useMemo(
    () => ({
      activeTab,
      tabs:
        width >= 1024
          ? [
              { label: '+ 1 Year', value: '1' },
              { label: '+ 3 Years', value: '3' },
              { label: '+ 5 Years', value: '5' },
              { label: '+ 7 Years', value: '7' },
            ]
          : [
              { label: '+ 1 Year', value: '1' },
              { label: '+ 3 Years', value: '3' },
              { label: '+ 5 Years', value: '5' },
            ],
      onChange: handleTabChange,
      onClear: handleTabClear,
    }),
    [activeTab, handleTabChange, handleTabClear, width],
  );

  const markerProps = useMemo(
    () => ({
      active: !!activeTab,
      age: (+userData.age + +activeTab).toLocaleString(),
      years: activeTab,
      sticky: rounds.length > 1 && +userData.age + +activeTab < 46,
      onClose: handleTabClear,
    }),
    [activeTab, handleTabClear, rounds.length, userData.age],
  );

  return {
    userData: {
      ...userData,
      weight: renderedWeight,
      height: renderedHeight,
    },
    tabsProps,
    markerProps,
    rounds,
    handleNext,
    handleBack,
  };
};

export default useResultsContainer;
